import { handleAuthError } from "utils/handleAuthError.js";
import {
  forgotPassword,
  loginAdmin,
  logOutAdmin,
  resetPassword,
  validateToken,
} from "store/services/session/auth.js";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { openToast } from "store/slices/common/toast.slice.js";
import LocalstorageService from "utils/localstorage-services.js";

export const LoginAdminAction = createAsyncThunk(
  "login/loginAdmin",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const res = await loginAdmin(payload);
      if (res.status === 1) {
        const { response } = res;
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        dispatch(openToast({ type: "success", message: response?.message }));
        return fulfillWithValue({ response });
      }
      dispatch(openToast({ type: "error", message: res?.message }));
      return rejectWithValue({ message: res?.message });
    } catch (error) {
      dispatch(openToast({ type: "error", message: error?.response?.data?.message ? error?.response?.data?.message : error?.message  }));
      return rejectWithValue(error);
    }
  }
);

export const LogOutAdminAction = createAsyncThunk(
  "login/logOutAdmin",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const res = await logOutAdmin();
      if (res.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        return fulfillWithValue({ response: res });
      }
      dispatch(openToast({ type: "error", message: res?.message }));
      return rejectWithValue({ message: res?.message });
    } catch (error) {
      dispatch(openToast({ type: "error", message: error?.message }));
      handleAuthError(error?.response?.status);
      return rejectWithValue(error);
    }
  }
);

export const ForgotPasswordAction = createAsyncThunk(
  "login/forgotPassword",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const res = await forgotPassword(payload);
      if (res.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        dispatch(openToast({ type: "success", message: res?.message }));
        return fulfillWithValue({ response: res });
      }
      dispatch(openToast({ type: "error", message: res?.message }));
      return rejectWithValue({ message: res?.message });
    } catch (error) {
      dispatch(openToast({ type: "error", message: error?.message }));
      return rejectWithValue(error);
    }
  }
);

export const ResetPasswordAction = createAsyncThunk(
  "login/resetPassword",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const res = await resetPassword(payload);
      if (res.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        dispatch(openToast({ type: "success", message: res?.message }));
        return fulfillWithValue(res);
      }
      dispatch(openToast({ type: "error", message: res?.message }));
      return rejectWithValue({ message: res?.message });
    } catch (error) {
      dispatch(openToast({ type: "error", message: error?.message }));
      return rejectWithValue(error);
    }
  }
);

export const ValidateTokenAdmin = createAsyncThunk(
  "auth/validateTokenAdmin",
  async (payload, { rejectWithValue, fulfillWithValue }) => {
    try {
      const res = await validateToken(payload);
      if (!res?.response?.isTokenExpired) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        return fulfillWithValue(res);
      }
      LocalstorageService.removeFromLocalStorage("aboutcare-admin");
      return rejectWithValue({ message: res.message });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

import React, { useEffect } from "react";
import { ChevronLeft } from "lucide-react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import logoSm from "assets/images/logo.svg";
import { changeLeftsidebarSizeType } from "store/slices/common/layout.slice";
import { ProfileMenu } from "components/atoms";

const Header = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { layoutType } = useSelector((state) => state.layout);

  const handleTopbarHamburgerIcon = () => {
    navigate(-1)
  };

  useEffect(() => {
    // resize
    const handleResizeLayout = () => {
      var windowSize = document.documentElement.clientWidth;

      if (windowSize < 768) {
        dispatch(changeLeftsidebarSizeType("lg"));
      } else if (windowSize <= 1024) {
        if (layoutType === "vertical") {
          dispatch(changeLeftsidebarSizeType("sm"));
        } else {
          dispatch(changeLeftsidebarSizeType("lg"));
        }
      } else {
        dispatch(changeLeftsidebarSizeType("lg"));

        // dispatch(changeLeftsidebarSizeType(layoutSidebarSizeType === "sm" ? "lg" : "sm"));
      }
    };

    // out side click
    const outerSideElement = () => {
      var windowSize = document.documentElement.clientWidth;
      var sidebarOverlay = document.getElementById("sidebar-overlay");
      if (sidebarOverlay) {
        sidebarOverlay.addEventListener("click", () => {
          if (!sidebarOverlay.classList.contains("hidden")) {
            if (windowSize <= 768) {
              document?.querySelector(".app-menu")?.classList.add("hidden");
              document.body.classList.remove("overflow-hidden");
              sidebarOverlay.classList.add("hidden");
            } else {
              dispatch(changeLeftsidebarSizeType("lg"));
            }
          }
        });
      }
    };

    // scroll
    const scrollNavigation = () => {
      var scrollUp = document.documentElement.scrollTop;
      if (scrollUp >= 50) {
        document.getElementById("page-topbar")?.classList.add("is-sticky");
      } else {
        document.getElementById("page-topbar")?.classList.remove("is-sticky");
      }
    };

    window.addEventListener("scroll", scrollNavigation, true);
    window.addEventListener("click", outerSideElement);
    window.addEventListener("resize", handleResizeLayout);

    // Cleanup function to remove the event listeners
    return () => {
      window.removeEventListener("scroll", scrollNavigation, true);
      window.removeEventListener("click", outerSideElement);
      window.removeEventListener("resize", handleResizeLayout);
    };
  }, [layoutType, dispatch]);
  return (
    <React.Fragment>
      <header
        id="page-topbar"
        className="md:left-vertical-menu 
                group-data-[sidebar-size=md]:md:left-vertical-menu-md 
                group-data-[sidebar-size=sm]:md:left-vertical-menu-sm 
                fixed right-0 z-[1000] left-0 print:hidden m-4
                [&.is-sticky]:mt-0
                ease-linear duration-300 
                group/topbar 
                "
      >
        <div className="layout-width">
          <div
            className="flex items-center px-4 mx-auto bg-topbar rounded-md group-[.is-sticky]/topbar:rounded-t-none shadow-md h-header shadow-slate-200/50 
          group-data-[layout=horizontal]:group-data-[navbar=bordered]:rounded-b-none shadow-md h-header shadow-slate-200/50
          "
          >
            <div className="flex items-center w-full navbar-header">
              <div className="items-center justify-center hidden px-5 text-center h-header">
                <Link to="/">
                  <span className="hidden">
                    <img
                      src={logoSm}
                      alt=""
                      className="h-6 mx-auto pointer-events-none"
                    />
                  </span>
                  <span className="">
                    <img
                      src={logoSm}
                      alt=""
                      className="h-6 mx-auto pointer-events-none"
                    />
                  </span>
                </Link>
                <Link to="/" className="hidden ">
                  <span className="">
                    <img
                      src={logoSm}
                      alt=""
                      className="h-6 mx-auto pointer-events-none"
                    />
                  </span>
                  <span className="">
                    <img
                      src={logoSm}
                      alt=""
                      className="h-6 mx-auto pointer-events-none"
                    />
                  </span>
                </Link>
              </div>

              <button
                onClick={handleTopbarHamburgerIcon}
                type="button"
                className="inline-flex relative justify-center items-center p-0 text-topbar-item transition-all size-[37.5px] duration-75 ease-linear rounded-md btn hamburger-icon"
              >
                <ChevronLeft className="w-5 h-5" />
              </button>

              <div className="flex gap-3 ms-auto">
                <ProfileMenu />
              </div>
            </div>
          </div>
        </div>
      </header>
    </React.Fragment>
  );
};

export default Header;

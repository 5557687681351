import axiosClient from "utils/api";

// For Fetch All FAQs
export const getFAQ = async (payload) => {
  let URL = payload ? `admin/faq/faqs/${payload.type}` : "admin/faq/faqs"
  try {
    const res = await axiosClient().request({
      url: URL,
      method: "GET",
      params: payload,
    });

    return res.data;
  } catch (error) {
    console.error("Error occuured Get All FAQ:", error.message);
    throw new Error(error.message);
  }
};

// For Fetch All FAQ Votes
export const getFaqVotes = async (payload) => {
  let { id, type } = payload;
  try {
    const res = await axiosClient().request({
      url: `/admin/faq/voters/${id}/${type}`,
      method: "GET",
    });

    return res.data;
  } catch (error) {
    console.error("Error occuured Get All Votes:", error.message);
    throw new Error(error.message);
  }
};

// For Add FAQ
export const addFAQ = async (payload) => {
  try {
    const res = await axiosClient().request({
      url: "admin/faq/faq",
      method: "POST",
      data: payload,
    });
    return res.data;
  } catch (error) {
    console.error("Error Occurred Add FAQ:", error.message);
    throw new Error(error.message);
  }
};

// For Update FAQ
export const updateFAQ = async (payload) => {
  let { id, ...rest } = payload;
  try {
    const res = await axiosClient().request({
      url: `admin/faq/faq/${id}`,
      method: "PUT",
      data: rest,
    });
    return res.data;
  } catch (error) {
    console.error("Error Occurred Update FAQ:", error.message);
    throw new Error(error.message);
  }
};

// For Delete FAQ
export const deleteFAQ = async (payload) => {
  try {
    const res = await axiosClient().request({
      url: `admin/faq/faq/${payload.id}`,
      method: "DELETE",
    });
    return res.data;
  } catch (error) {
    console.error("Error Occurred Delete FAQ:", error.message);
    throw new Error(error.message);
  }
};

// For Update Visibility
export const updateVisibilityFAQ = async (payload) => {
  let { id, ...rest } = payload;
  try {
    const response = await axiosClient().request({
      url: `admin/faq/visibility/${id}`,
      method: "put",
      data: rest,
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred Update FAQ visibility:", error);
    throw error;
  }
};

// For Fetch Emails
export const getAllEmail = async (payload) => {
  let URL = payload ? `admin/emailHelpSupport/getalluserqueries/${payload.type}` : "admin/emailHelpSupport/getalluserqueries"
  try {
    const res = await axiosClient().request({
      url: URL,
      method: "GET",
    });
    return res.data;
  } catch (error) {
    console.error("Error occuured Get All Emails:", error.message);
    throw new Error(error.message);
  }
};

// For Fetch Emails
export const updateStatus = async (payload) => {
  let { id, ...rest } = payload;
  try {
    const res = await axiosClient().request({
      url: `admin/emailHelpSupport/updatestatus/${id}`,
      method: "PUT",
      data: rest,
    });
    return res.data;
  } catch (error) {
    console.error("Error occuured Update Status:", error.message);
    throw new Error(error.message);
  }
};

// For Fetch h&s chat list
export const getAllChatList = async () => {
  try {
    const res = await axiosClient().request({
      url: "admin/helpAndSupportChat/getChat",
      method: "GET",
    });
    return res.data;
  } catch (error) {
    console.error("Error occuured Get All h&s Chatlist:", error.message);
    throw new Error(error.message);
  }
};

// For Add Chat
export const addChat = async (payload) => {
  const { id } = payload;
  try {
    const res = await axiosClient().request({
      url: `admin/helpAndSupportChat/addChat/${id}`,
      method: "PUT",
    });
    return res.data;
  } catch (error) {
    console.error("Error occuured Add Chat:", error.message);
    throw new Error(error.message);
  }
};

// For Add Chat
export const getMessages = async (payload) => {
  const { id } = payload;
  try {
    const res = await axiosClient().request({
      url: `admin/helpAndSupportChat/getMessage/${id}`,
      method: "GET",
    });
    return res.data;
  } catch (error) {
    console.error("Error occuured Get Messages:", error.message);
    throw new Error(error.message);
  }
};

// For add message
export const addMessage = async (payload) => {
  try {
    const res = await axiosClient().request({
      url: "admin/helpAndSupportChat/addMessage",
      method: "POST",
      data: payload,
    });
    return res.data;
  } catch (error) {
    console.error("Error occuured add message:", error.message);
    throw new Error(error.message);
  }
};

// For delete message
export const deleteMessage = async (payload) => {
  const { id } = payload;
  try {
    const res = await axiosClient().request({
      url: `admin/helpAndSupportChat/deleteChat/${id}`,
      method: "DELETE",
    });
    return res.data;
  } catch (error) {
    console.error("Error occuured delete message:", error.message);
    throw new Error(error.message);
  }
};

// For delete message
export const finishChat = async (payload) => {
  const { id } = payload;
  try {
    const res = await axiosClient().request({
      url: `admin/helpAndSupportChat/finishChat/${id}`,
      method: "PUT",
    });
    return res.data;
  } catch (error) {
    console.error("Error occuured finish chat:", error.message);
    throw new Error(error.message);
  }
};

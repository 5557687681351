import { Box, IconButton } from "@mui/material";
import { Pencil, Trash2 } from "lucide-react";
import React from "react";
import { useDispatch } from "react-redux";
import { openAdminModal } from "store/slices/common/adminModal.slice";
import DeleteIconSVGModel from "assets/images/deleteIconSVGModel.svg";
import Swal from "sweetalert2";
import { ModalEditUsers } from "components/molecules";
import { TooltipProvider } from "components/atoms";
import checkAccess from "utils/checkAccess";
import { DeleteAccessManagementUsers } from "store/middlewares/accessManagementUsers";
// import { DeleteAccessManagementUsers } from "store/middlewares/accessManagementUsers";

const Users = ({ row }) => {
    const dispatch = useDispatch();

    return (
        <Box className="flex h-full items-center justify-end gap-3">
            {checkAccess("edit_users") && (
                <IconButton
                    color="info"
                    disableRipple
                    onClick={() =>
                        dispatch(
                            openAdminModal({
                                title: "Edit Access",
                                content: <ModalEditUsers row={row} />,
                            })
                        )
                    }
                >
                    <TooltipProvider title="Edit">
                        <Pencil />
                    </TooltipProvider>
                </IconButton>
            )}
            {checkAccess("delete_users") && (
                <IconButton
                    color="error"
                    disableRipple
                    onClick={() => {
                        Swal.fire({
                            title: "Are you sure you want to delete this User?",
                            imageUrl: DeleteIconSVGModel,
                            imageWidth: 100,
                            imageHeight: 100,
                            showCancelButton: true,
                            confirmButtonColor: "#3085d6",
                            cancelButtonColor: "#d33",
                            confirmButtonText: "Confirm",
                            customClass: {
                                title: "Swal-title-class",
                                popup: "custom-swal-popup",
                            },
                        }).then((result) => {
                            if (result.isConfirmed) {
                                dispatch(DeleteAccessManagementUsers({ id: row.id }));
                            }
                        });
                    }}
                >
                    <TooltipProvider title="Delete">
                        <Trash2 />
                    </TooltipProvider>
                </IconButton>
            )}
        </Box>
    );
};

export default Users;

import { createSlice } from "@reduxjs/toolkit";
import {
  AddRoleAction,
  DeleteRoleAction,
  GetAllRolesAction,
  UpdateRoleAction,
} from "store/middlewares/roles";
import updateAccessInLocalStorage from "utils/updateAccess";

export const rolesSlice = createSlice({
  name: "roles",
  initialState: {
    rolesLoading: false,
    roles: [],
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(GetAllRolesAction.pending, (state) => {
        state.rolesLoading = true;
        state.roles = [];
      })
      .addCase(GetAllRolesAction.fulfilled, (state, action) => {
        state.rolesLoading = false;
        state.roles = action.payload.response.data
          ? action.payload.response.data
          : action.payload.response;
      })
      .addCase(GetAllRolesAction.rejected, (state) => {
        state.rolesLoading = false;
        state.roles = [];
      })
      // Delete Job Title
      .addCase(DeleteRoleAction.fulfilled, (state, action) => {
        let { id } = action.payload;
        let tempArray = state.roles?.filter((title) => title.id !== id);
        state.rolesLoading = false;
        state.roles = tempArray;
      })
      //Update Job Title
      .addCase(UpdateRoleAction.fulfilled, (state, action) => {
        let obj = action.payload.response.data
          ? action.payload.response.data
          : action.payload.response;
        let { id, ...rest } = obj;
        let accessArray = obj?.access?.map((acc) => acc.type)
        let roleName = obj?.name
        updateAccessInLocalStorage(roleName, accessArray)
        let tempArray = state.roles?.map((title) => {
          if (title.id === id) {
            return { ...title, ...rest };
          }
          return { ...title };
        });
        state.rolesLoading = false;
        state.roles = tempArray;
      })
      //Add city
      .addCase(AddRoleAction.fulfilled, (state, action) => {
        state.rolesLoading = false;
        let Obj = action.payload.response.newcode
          ? action.payload.response.newcode
          : action.payload.response;
        state.roles = [...state.roles, Obj];
      });
  },
});

export default rolesSlice.reducer;

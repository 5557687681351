import React from "react";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import { SubmitBtn, InputTextarea } from "components/atoms";
import { Reason } from "utils/validation/AddJobCancellation";
import { BlockProfessionalAction } from "store/middlewares/users/professionals";

function ModalBlockUser({ row }) {
  const dispatch = useDispatch();
  const intitalVal = {
    blockReason: "",
  };

  const AddJobTitleSchema = Yup.object({ blockReason: Reason });

  const handleSubmit = (values) => {
    dispatch(BlockProfessionalAction({ id: row.id, blockReason: values }));
  };

  const formik = useFormik({
    initialValues: intitalVal,
    validationSchema: AddJobTitleSchema,
    enableReinitialize: true,
    onSubmit: handleSubmit,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <InputTextarea
        textareaClassName="rounded-lg border border-solid border-zinc-400 w-full px-2 py-2 mt-2 no-scrollbar"
        label={"Block Reason"}
        name="blockReason"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.errors.blockReason && formik.touched.blockReason}
        errorMsg={formik.errors.blockReason}
        value={formik.values.blockReason}
        placeholder="Provide a reason for blocking"
      />
      <SubmitBtn title={"Block"} />
    </form>
  );
}

export default ModalBlockUser;

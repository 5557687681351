import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom/client";
// import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import configStore from "./store";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import "./assets/scss/themes.scss";
import "./assets/styles/global.scss";
import { ErrorBoundary } from "react-error-boundary";
const ErrorFallback = lazy(() => import("./components/fallbackUI/Error"));
const Loader = lazy(() => import("./components/fallbackUI/Loading"));
const store = configStore();
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Router>
        <Suspense fallback={<Loader />}>
          <Provider store={store}>
            <App />
          </Provider>
        </Suspense>
      </Router>
    </ErrorBoundary>
  </React.StrictMode>
);

reportWebVitals();

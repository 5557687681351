import React, { useEffect, useState } from "react";
import RejectSVGModel from "assets/images/RejectSVGModel.svg";
import ApprovedSVGModel from "assets/images/ApprovedSVGModel.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  GetAllCredentialCertificateAction,
  ManageAllCredentialCertificateAction,
} from "store/middlewares/credential";
import {
  AddButton,
  CustomLoader,
  DeleteButton,
  NoDataFound,
} from "components/atoms";
import Swal from "sweetalert2";
import checkAccess from "utils/checkAccess";

function Certificate({ userId }) {
  const dispatch = useDispatch();
  const { certificate, certificateLoading } = useSelector(
    (state) => state.credential
  );
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  // Function to open/close the modal
  const toggleModal = (imageSrc) => {
    setSelectedImage(imageSrc);
    setModalOpen(!isModalOpen);
  };

  useEffect(() => {
    dispatch(GetAllCredentialCertificateAction({ id: userId }));
  }, [dispatch, userId]);
  return (
    <React.Fragment>
      <div className="card">
        <div className="card-body">
          <h6 className="mb-4 text-16 font-bold underline">Certificate : </h6>
          {!certificateLoading ? (
            <>
              {certificate && certificate.length > 0 ? (
                <div className="grid grid-cols-2 gap-4">
                  {certificate.map((item) => (
                    <div className="card" key={item?.id}>
                      <div className="card-body px-0">
                        <div className="relative overflow-hidden rounded-md group/gallery">
                          <div className="overflow-hidden">
                            <img
                              src={item.imageUrl}
                              alt=""
                              className="transition-all w-full h-[300px] object-cover duration-300 ease-linear group-hover/gallery:scale-110 "
                              onClick={() => toggleModal(item.imageUrl)}
                            />
                          </div>
                        </div>
                        <div className="mt-2">
                          <h6 className="text-base capitalize">
                            <span className="font-bold">
                              Certificate Name:{" "}
                            </span>
                            {item?.name}
                          </h6>
                          <h6 className="text-base capitalize">
                            <span className="font-bold">
                              Certificate Number:{" "}
                            </span>
                            {item?.number}
                          </h6>
                          <h6 className="text-base capitalize">
                            <span className="font-bold">
                              Expiry Date:{" "}
                            </span>
                            {item?.expiryDate}
                          </h6>
                        </div>
                        {checkAccess("edit_credential") && (
                          <div className="text-end mt-2">
                            {item.status !== "accepted" && (
                              <AddButton
                                title="Accept"
                                onClick={() => {
                                  Swal.fire({
                                    title:
                                      "Are you sure you want to accept this certificate?",
                                    imageUrl: ApprovedSVGModel,
                                    imageWidth: 180,
                                    imageHeight: 180,
                                    showCancelButton: true,
                                    confirmButtonColor: "#00AE46",
                                    cancelButtonColor: "#d33",
                                    confirmButtonText: "Confirm",
                                    customClass: {
                                      title: "Swal-title-class",
                                      popup: "custom-swal-popup",
                                    },
                                  }).then((result) => {
                                    if (result.isConfirmed) {
                                      dispatch(
                                        ManageAllCredentialCertificateAction({
                                          id: item.id,
                                          status: "accepted",
                                        })
                                      );
                                    }
                                  });
                                }}
                              />
                            )}
                            {item.status !== "rejected" && (
                              <DeleteButton
                                title="Reject"
                                style="ml-2"
                                onClick={() => {
                                  Swal.fire({
                                    title:
                                      "Are you sure you want to reject this certificate?",
                                    imageUrl: RejectSVGModel,
                                    imageWidth: 180,
                                    imageHeight: 180,
                                    showCancelButton: true,
                                    confirmButtonColor: "#00AE46",
                                    cancelButtonColor: "#d33",
                                    confirmButtonText: "Confirm",
                                    customClass: {
                                      title: "Swal-title-class",
                                      popup: "custom-swal-popup",
                                    },
                                  }).then((result) => {
                                    if (result.isConfirmed) {
                                      dispatch(
                                        ManageAllCredentialCertificateAction({
                                          id: item.id,
                                          status: "rejected",
                                        })
                                      );
                                    }
                                  });
                                }}
                              />
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <NoDataFound />
              )}
            </>
          ) : (
            <CustomLoader />
          )}
        </div>
      </div>

      {isModalOpen && selectedImage && (
        <div
          className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-[999999999999]"
          onClick={toggleModal}
        >
          <img
            src={selectedImage}
            alt=""
            className="max-h-full max-w-full rounded-md"
          />
        </div>
      )}
    </React.Fragment>
  );
}

export default Certificate;

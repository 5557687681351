import * as Yup from "yup";
import { emailRegex, phoneRegExp, spaceRegExp } from "utils/regex";

export const email = Yup.string()
  .email("Email is invalid")
  .required("Email is required")
  .matches(emailRegex, "Email is invalid");

export const confirmEmail = Yup.string()
  .oneOf([Yup.ref("email"), null], "Email and confirm email do not match!")
  .required("Confirm email is required");

export const Password = Yup.string()
  .required("Password is required")
  .min(6, "Password should be at least 6 characters long")
  .matches(spaceRegExp, "Password cannot contain spaces")
  .max(50, "Password should not contain more than 50 characters");

export const confirmPassword = Yup.string()
  .required("Confirm password is required")
  .oneOf(
    [Yup.ref("password"), null],
    "Password and confirm password do not match"
  );

export const File = Yup.mixed()
.required("Profile image is required")
.test("fileSize", "Please select a valid image file with size up to 3MB", (value) => {
  return value && value.size <= 3 * 1024 * 1024; // 3MB limit
})
 
export const Name = Yup.string().required("Hospital name is required").matches(/^[^\s].*$/, "Name cannot start with a space");
export const fName = Yup.string().required("First name is required").matches(/^[^\s].*$/, "First name cannot start with a space");
export const lName = Yup.string().required("Last name is required").matches(/^[^\s].*$/, "Last name cannot start with a space");
export const Nickname =  Yup.string().nullable();
export const AboutMe = Yup.string().required("About Me is required").matches(/^[^\s].*$/, "About me cannot start with a space");
export const DUNS = Yup.string().required("DUNS is required");
export const Facility = Yup.string().required("Facility is required");
export const jobTitle = Yup.string().required("Please select job title");
export const zipCode = Yup.string().required("ZIP code is required");
export const country = Yup.string().required("Please select country");
export const state = Yup.string().required("Please select state");
export const city = Yup.string().required("Please select city");
export const Landmark = Yup.string().required("Landmark is required").matches(/^[^\s].*$/, "Landmark cannot start with a space");
export const unitId = Yup.string().required("Unit id is required");
export const addressType = Yup.string().required("Address type is required").matches(/^[^\s].*$/, "Address type cannot start with a space");
export const firstAddress = Yup.string().required("Address 1 is required");
export const secondAddress = Yup.string().nullable();
export const Title = Yup.string().required("Please select title");
export const Phone = Yup.string()
  .required("Phone number is required")
  .matches(phoneRegExp, "Phone number is not valid")
  .max(10, "Phone number should be less than 10 digits")
  .min(10, "Phone number should be 10 digits");
export const Speciality = Yup.array()
  .of(Yup.string().required("Speciality is required"))
  .min(1, "Please select speciality")
  .required("Speciality is required");
export const CountryCode = Yup.string().required("Country code is required");

import { createSlice } from "@reduxjs/toolkit";
import {AddBadgesAction,DeleteBadgesAction,UpdateBadgesAction,GetAllBadgesAction} from "store/middlewares/badges";

export const badgesSlice = createSlice({
  name: "badges",
  initialState: {
    badgesLoading: false,
    badges: [],
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(GetAllBadgesAction.pending, (state) => {
        state.badgesLoading = true;
        state.badges = [];
      })
      .addCase(GetAllBadgesAction.fulfilled, (state, action) => {
        state.badgesLoading = false;
        state.badges = action.payload.response;
      })
      .addCase(GetAllBadgesAction.rejected, (state) => {
        state.badgesLoading = false;
        state.badges = [];
      })
      // Delete banner
      .addCase(DeleteBadgesAction.fulfilled, (state, action) => {
        let { id } = action.payload;
        let tempArray = state.badges?.filter((title) => title.id !== id);
        state.badgesLoading = false;
        state.badges = tempArray;
      })
      //Update banner
      .addCase(UpdateBadgesAction.fulfilled, (state, action) => {
        let {
          response: { id, ...rest },
        } = action.payload;

        let tempArray = state.badges?.map((title) => {
          if (title.id === id) {
            return { ...title, ...rest, bannerSignedUrl: rest.signedUrl };
          }
          return { ...title };
        });
        state.badgesLoading = false;
        state.badges = tempArray;
      })
      //Add banner
      .addCase(AddBadgesAction.fulfilled, (state, action) => {
        let tempObj = {
          ...action.payload.response,
          bannerSignedUrl: action.payload.response.signedUrl,
        };
        state.badgesLoading = false;
        state.badges = [tempObj, ...state.badges];
      })

  },
});

export default badgesSlice.reducer;

import axiosClient from "utils/api";
const URI = "/admin/banner";

export const getBanners = async () => {
  const URL = `${URI}/banner`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "get",
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred Get All Banners:", error);
    throw error;
  }
};

export const deleteBanner = async (payload) => {
  const URL = `${URI}/banner/${payload.id}`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "delete",
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred Delete Banner:", error);
    throw error;
  }
};

export const updateBanner = async (payload) => {
  let { id, ...rest } = payload;
  const URL = `${URI}/banner/${id}`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "put",
      data: rest,
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred Update Banner:", error);
    throw error;
  }
};

export const addBanner = async (payload) => {
  const URL = `${URI}/banner`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "post",
      data: payload,
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred Add Banner:", error);
    throw error;
  }
};

export const updateVisiblityBanner = async (payload) => {
  let { id, ...rest } = payload;
  const URL = `${URI}/visibility/${id}`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "put",
      data: rest,
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred Update Facility:", error);
    throw error;
  }
};
import React, { useEffect } from "react";
import RejectSVGModel from "assets/images/RejectSVGModel.svg";
import ApprovedSVGModel from "assets/images/ApprovedSVGModel.svg";
import { useState } from "react";
import {
  GetAllCredentialServiceLicenceAction,
  ManageAllCredentialServiceLicenceAction,
} from "store/middlewares/credential";
import { useDispatch, useSelector } from "react-redux";
import {
  AddButton,
  CustomLoader,
  DeleteButton,
  NoDataFound,
} from "components/atoms";
import Swal from "sweetalert2";
import checkAccess from "utils/checkAccess";

function ProfessionalLicence({ userId }) {
  const dispatch = useDispatch();
  const { serviceLicence, serviceLicenceLoading } = useSelector(
    (state) => state.credential
  );
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  // Function to open/close the modal
  const toggleModal = (imageSrc) => {
    setSelectedImage(imageSrc);
    setModalOpen(!isModalOpen);
  };

  useEffect(() => {
    dispatch(GetAllCredentialServiceLicenceAction({ id: userId }));
  }, [dispatch, userId]);
  return (
    <React.Fragment>
      <div className="card">
        <div className="card-body">
          <h6 className="mb-4 text-16 font-bold underline">
            Professional Licence :
          </h6>
          {!serviceLicenceLoading ? (
            <>
              {serviceLicence && serviceLicence.length > 0 ? (
                <div className="grid grid-cols-1 gap-4">
                  {serviceLicence.map((item) => (
                    <div className="card" key={item?.id}>
                      <div className="card-body px-0">
                        <div className="relative overflow-hidden rounded-md group/gallery">
                          <div className="overflow-hidden">
                            <img
                              src={item.imageUrl}
                              alt=""
                              className="transition-all w-full h-[300px] object-cover duration-300 ease-linear group-hover/gallery:scale-110  "
                              onClick={() => toggleModal(item.imageUrl)}
                            />
                          </div>
                        </div>
                        <div className="grid grid-cols-2 mt-5 gap-4">
                          <div className="">
                            <h6 className="text-base">
                              <span className="font-bold">
                                {" "}
                                Speciality Name :{" "}
                              </span>
                              {item?.speciality?.speciality}
                            </h6>
                            <h6 className="text-base">
                              <span className="font-bold"> Licence No. : </span>
                              {item?.licenceNo}
                            </h6>
                          </div>
                          <h6 className="text-base">
                            <span className="font-bold"> Time Duration </span>{" "}
                            <br />
                            <span className="font-bold"> Issued Date : </span>
                            {item?.issuedMonth}/{item?.issuedYear}
                            <br />
                            <span className="font-bold">
                              {" "}
                              Expiry Date:
                            </span>{" "}
                            {item?.expiryMonth}/{item?.expiryYear}
                          </h6>
                        </div>
                        {checkAccess("edit_credential") && (
                          <div className="text-end mt-2">
                            {item.status !== "accepted" && (
                              <AddButton
                                title="Accept"
                                onClick={() => {
                                  Swal.fire({
                                    title:
                                      "Are you sure you want to accept this avatar?",
                                    imageUrl: ApprovedSVGModel,
                                    imageWidth: 180,
                                    imageHeight: 180,
                                    showCancelButton: true,
                                    confirmButtonColor: "#00AE46",
                                    cancelButtonColor: "#d33",
                                    confirmButtonText: "Confirm",
                                    customClass: {
                                      title: "Swal-title-class",
                                      popup: "custom-swal-popup",
                                    },
                                  }).then((result) => {
                                    if (result.isConfirmed) {
                                      dispatch(
                                        ManageAllCredentialServiceLicenceAction(
                                          {
                                            id: item.id,
                                            status: "accepted",
                                          }
                                        )
                                      );
                                    }
                                  });
                                }}
                              />
                            )}
                            {item.status !== "rejected" && (
                              <DeleteButton
                                title="Reject"
                                style="ml-2"
                                onClick={() => {
                                  Swal.fire({
                                    title:
                                      "Are you sure you want to reject this avatar?",
                                    imageUrl: RejectSVGModel,
                                    imageWidth: 180,
                                    imageHeight: 180,
                                    showCancelButton: true,
                                    confirmButtonColor: "#00AE46",
                                    cancelButtonColor: "#d33",
                                    confirmButtonText: "Confirm",
                                    customClass: {
                                      title: "Swal-title-class",
                                      popup: "custom-swal-popup",
                                    },
                                  }).then((result) => {
                                    if (result.isConfirmed) {
                                      dispatch(
                                        ManageAllCredentialServiceLicenceAction(
                                          {
                                            id: item.id,
                                            status: "rejected",
                                          }
                                        )
                                      );
                                    }
                                  });
                                }}
                              />
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <NoDataFound />
              )}
            </>
          ) : (
            <CustomLoader />
          )}
        </div>
      </div>

      {isModalOpen && selectedImage && (
        <div
          className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-[999999999999]"
          onClick={toggleModal}
        >
          <img
            src={selectedImage}
            alt=""
            className="max-h-full max-w-full rounded-md size-[1000px] pointer-events-none "
          />
        </div>
      )}
    </React.Fragment>
  );
}

export default ProfessionalLicence;

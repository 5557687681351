import React, { useRef, useState } from "react";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import { DateInput, Radio, SelectComponent, SubmitBtn } from "components/atoms";
import BlankSvg from "assets/images/blank.png";
import { AddBannerAction } from "store/middlewares/banner";
import {
  BannerType,
  EndDate,
  FileVideoType,
  ImageFile,
  StartDate,
} from "utils/validation/AddBanner";
import { Grid } from "@mui/material";
import { GalleryThumbnails } from "lucide-react";
import VideoThumbnailExtractor from "utils/thumbnailGenerate";

function ModalAddBanner({ platFormType }) {

  const dispatch = useDispatch();
  const [imageSrc, setImageSrc] = useState(BlankSvg);
  const [thumbnail, setThumbnail] = useState(null);
  // const [errorMessage, setErrorMessage] = useState("");
  const [videoPreview, setVideoPreview] = useState(null);
  // const [videoErrorMessage, setVideoErrorMessage] = useState(null);

  const intitalVal = {
    fileType: "",
    fileVideoType: "",
    bannerType: "",
    bannerFileType: "image",
    startDate: "",
    endDate: "",
  };

  const AddSpecialitySchema = {
    bannerType: BannerType,
    startDate: StartDate,
    endDate: EndDate,
  };

  const [bannerValidationSchema, setBannerValidationSchema] = useState({
    fileType: ImageFile,
  });

  const handleSubmit = (values) => {
    let payload = {
      ...values,
      platFormType: platFormType,
      file:
        values.bannerFileType === "image"
          ? values.fileType
          : values.fileVideoType,
          ...(thumbnail && values.bannerFileType === "video" && { thumbnail }),
    };
    delete payload["type"];
    delete payload["fileVideoType"];
    delete payload["fileType"];
    console.log(payload,"payload");
    
    dispatch(AddBannerAction(payload));
  };

  const formik = useFormik({
    initialValues: intitalVal,
    validationSchema: Yup.object({
      ...AddSpecialitySchema,
      ...bannerValidationSchema,
    }),
    enableReinitialize: true,
    onSubmit: handleSubmit,
  });

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const fileSizeLimit = 3 * 1024 * 1024;
    const reader = new FileReader();

    if (file) {
      if (file.size <= fileSizeLimit) {
        reader.onload = () => {
          setImageSrc(reader.result);
          formik.setFieldValue("fileType", file);
        };
        reader.readAsDataURL(file);
      } else {
        formik.setFieldValue("fileType", "");
        formik.setFieldTouched("fileType", true);
      }
    }
  };

  const handleVideoFileChange = (e) => {
    const file = e.target.files[0];
    setVideoPreview(null);

    if (file) {
      //--------------- Thumbnails --------------------
       VideoThumbnailExtractor(file,setThumbnail)
      //--------------- Thumbnails --------------------

      // Check file size (10MB)
      if (file.size > 10 * 1024 * 1024) {
        formik.setFieldError(
          "fileVideoType",
          "File size must be less than 10 MB."
        );
        // formik.setFieldTouched("fileVideoType", true);
        return;
      }

      const video = document.createElement("video");
      video.preload = "metadata";
      // console.log("call");
      video.onloadedmetadata = () => {
        if (video.duration > 120) {
          formik.setFieldError(
            "fileVideoType",
            "Video duration must be less than 2 minutes."
          );
          // formik.setFieldTouched("fileVideoType", true);
          return;
        }

        // Set video preview
        const videoUrl = URL.createObjectURL(file);
        setVideoPreview(videoUrl);
        formik.setFieldValue("fileVideoType", file);
      };

      video.src = URL.createObjectURL(file);
    }
  };

  const radioOptions = [
    { value: "image", label: "Image" },
    { value: "video", label: "Video" },
  ];

  const bannerTypeOptions = [
    { label: "Home Page", value: "homePage" },
    // { label: "About Us Page", value: "aboutUsPage" },
  ];

  return (
    <form onSubmit={formik.handleSubmit}>
      <Radio
        label="Banner Type"
        RadioOption={radioOptions}
        name={"bannerFileType"}
        value={formik.values.bannerFileType}
        onChange={(e) => {
          formik.setFieldValue("bannerFileType", e.target.value);
          e.target.value === "image"
            ? setBannerValidationSchema({ fileType: ImageFile })
            : setBannerValidationSchema({ fileVideoType: FileVideoType });
        }}
      />
      {formik.values.bannerFileType === "image" ? (
        <div className="">
          <label className="font-medium">Banner Image</label>
          <br />
          <div className="flex flex-col mt-2 gap-x-2.5">
            <div className="relative">
              <div className="h-48 w-full bg-white">
                <input
                  type="file"
                  name="fileType"
                  accept="image/png, image/jpeg,image/jpg"
                  id="editimg"
                  onChange={(e) => {
                    handleFileChange(e);
                    formik.setFieldValue("fileType", e.target.files[0]);
                  }}
                  hidden
                />
                <label htmlFor="editimg" className="cursor-pointer">
                  <div className="w-full h-48 overflow-hidden">
                    <img
                      src={imageSrc}
                      alt="flag"
                      className="w-full h-full object-cover"
                    />
                  </div>
                </label>
              </div>
            </div>
          </div>
          {formik.touched.fileType && formik.errors.fileType && (
            <div className="text-red-500 text-sm mt-1">
              {formik.errors.fileType}
            </div>
          )}
        </div>
      ) : (
        <div className="">
          <label className="font-medium">Banner Video</label>
          <br />
          <div className="flex flex-col mt-2 gap-x-2.5">
            <div className="relative">
              <div className="w-full bg-white">
                <input
                  type="file"
                  name="fileVideoType"
                  accept="video/mp4, video/.mov,video/.mkv,video/.wmv,video/.webm"
                  id="editVideo"
                  onChange={(e) => {
                    handleVideoFileChange(e);
                  }}
                  hidden
                />
                <label
                  htmlFor="editVideo"
                  className="text-white btn px-3 bg-[#0ca7dfd3] hover:text-white hover:bg-[#0CA8DF] p-2 rounded-lg"
                >
                  Choose Video
                </label>
              </div>
            </div>
          </div>
          {/* Show Formik validation error if present */}
          {formik.errors.fileVideoType && formik.touched.fileVideoType && (
            <div className="text-red-500 text-sm mt-1">
              {formik.errors.fileVideoType}
            </div>
          )}
          {videoPreview && (
            <div className="mt-2">
              <video width="100%" className="h-[300px] object-cover" controls>
                <source src={videoPreview} type="video/mp4" />
                <source src={videoPreview} type="video/quicktime" />{" "}
                {/* for .mov */}
                <source src={videoPreview} type="video/x-matroska" />{" "}
                {/* for .mkv */}
                <source src={videoPreview} type="video/x-ms-wmv" />{" "}
                {/* for .wmv */}
                <source src={videoPreview} type="video/webm" />
                Your browser does not support the video tag.
              </video>
            </div>
          )}
                {/* {thumbnail && <img src={thumbnail} alt="Video Thumbnail" />} */}
        </div>
      )}
      <Grid container columnSpacing={5} rowSpacing={3}>
        <Grid item md={6} xs={12}>
          <DateInput
            inputClassName="rounded-lg border border-solid border-zinc-400 w-full px-2 py-2 mt-2"
            label={"Start Date"}
            type="date"
            name="startDate"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.errors.startDate && formik.touched.startDate}
            errorMsg={formik.errors.startDate}
            value={formik.values.startDate}
            placeholder="Enter Start Date"
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <DateInput
            inputClassName="rounded-lg border border-solid border-zinc-400 w-full px-2 py-2 mt-2"
            label={"End Date"}
            type="date"
            name="endDate"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.errors.endDate && formik.touched.endDate}
            errorMsg={formik.errors.endDate}
            value={formik.values.endDate}
            placeholder="Enter End Date"
          />
        </Grid>
      </Grid>
      <SelectComponent
        className={"px-0"}
        label={"Banner Type"}
        options={bannerTypeOptions}
        placeholder="Select Banner Type"
        name={"bannerType"}
        value={formik.values.bannerType}
        error={formik.errors.bannerType && formik.touched.bannerType}
        errorMsg={formik.errors.bannerType}
        onChange={(selectedOption) => {
          formik.setFieldValue(
            "bannerType",
            selectedOption ? selectedOption?.value : ""
          );
        }}
      />
      <SubmitBtn title={"Add"} />
    </form>
  );
}

export default ModalAddBanner;

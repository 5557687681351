import React, { useEffect } from "react";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { Input, PasswordInput, SelectComponent, SubmitBtn } from "components/atoms";
import { Name, Email, Password, Role } from "utils/validation/AddAccessManagementUser";
import { GetAllRolesAction } from "store/middlewares/roles";
import { AddAccessManagementUsers } from "store/middlewares/accessManagementUsers";

function ModalAddUsers() {
    const dispatch = useDispatch();
    const { roles } = useSelector((state) => state.roles);

    useEffect(() => {
        dispatch(GetAllRolesAction());
    }, [dispatch]);

    const intitalVal = {
        name: "",
        email: "",
        password: "",
        rolesId: "",
    };
    const AddAccessSchema = Yup.object({ name: Name, email: Email, password: Password, rolesId: Role });
    const handleSubmit = (values) => {
        dispatch(AddAccessManagementUsers(values));
    };
    const formik = useFormik({
        initialValues: intitalVal,
        validationSchema: AddAccessSchema,
        enableReinitialize: true,
        onSubmit: handleSubmit,
    });

    return (
        <form onSubmit={formik.handleSubmit}>
            <Input inputClassName="rounded-lg border border-solid border-zinc-400 w-full px-2 py-2 mt-2"
                label={"Name"}
                error={formik.errors.name && formik.touched.name}
                errorMsg={formik.errors.name}
                value={formik.values.name}
                type="text"
                name="name"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder="Enter Name"
            />
            <Input inputClassName="rounded-lg border border-solid border-zinc-400 w-full px-2 py-2 mt-2"
                label={"Email"}
                error={formik.errors.email && formik.touched.email}
                errorMsg={formik.errors.email}
                value={formik.values.email}
                type="email"
                name="email"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder="Enter Email Address"
            />
            <PasswordInput
                inputClassName="rounded-lg border border-solid border-zinc-400 w-full px-2 py-2"
                label={"Password"}
                name="password"
                placeholder="Enter password"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.password || ""}
                error={
                    formik.touched.password && formik.errors.password
                }
                errorMsg={formik.errors.password}
            />
            <SelectComponent
                selectclassName={'mt-2'}
                className={"px-0"}
                label={"Role"}
                multiSelect={false}
                options={roles?.map((role) => {
                    return {
                        label: role?.name,
                        value: role?.id,
                    };
                })}
                placeholder="Select Role"
                name={"role"}
                value={formik.values.rolesId}
                error={formik.errors.rolesId && formik.touched.rolesId}
                errorMsg={formik.errors.rolesId}
                onChange={(selectedOption) => {
                    formik.setFieldValue("rolesId", selectedOption ? selectedOption.value : "");
                }}
            />
            <SubmitBtn title={"Add"} />
        </form>
    );
}

export default ModalAddUsers;

import { createSlice } from "@reduxjs/toolkit";
import {
  BlockProfessionalAction,
  GetProfessionalByIdAction,
  GetProfessionalUserHolidays,
  UnblockProfessionalAction,
} from "store/middlewares/users/professionals";
import {
  BlockHospitalAction,
  EditHospitalAddressAction,
  GetHospitalAddressAction,
  GetHospitalByIdAction,
  GetSubscriptionHospitalAction,
  UnblockHospitalAction,
} from "store/middlewares/users/hospitals";
import { UpdateProfessionalUserResume } from "store/middlewares/credential";

const initialState = {
  getUserLoading: false,
  user: {},
  holidays: [],
  holidaysLoading: false,
  getSubscriptionLoading: false,
  Subscription: [],
  address: {},
  addressLoading: false,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  extraReducers(builder) {
    builder
      // For Get Single Professional User
      .addCase(GetProfessionalByIdAction.pending, (state) => {
        state.user = null;
        state.getUserLoading = true;
      })
      .addCase(GetProfessionalByIdAction.fulfilled, (state, action) => {
        state.user = action.payload.response;
        state.getUserLoading = false;
      })
      .addCase(GetProfessionalByIdAction.rejected, (state) => {
        state.user = null;
        state.getUserLoading = false;
      })

      // For Get Hospital
      .addCase(GetHospitalByIdAction.pending, (state) => {
        state.user = null;
        state.getUserLoading = true;
      })
      .addCase(GetHospitalByIdAction.fulfilled, (state, action) => {
        state.user = action.payload.response;
        state.getUserLoading = false;
      })
      .addCase(GetHospitalByIdAction.rejected, (state) => {
        state.user = null;
        state.getUserLoading = false;
      })

      // For Block Professional User
      .addCase(BlockProfessionalAction.fulfilled, (state, action) => {
        state.getUserLoading = false;
        state.user = { ...state.user, ...action.payload.response };
      })

      // For Unblock Professional User
      .addCase(UnblockProfessionalAction.fulfilled, (state, action) => {
        state.user = { ...state.user, ...action.payload.response };
        state.getUserLoading = false;
      })

      // For Update Status Of Resume
      .addCase(UpdateProfessionalUserResume.fulfilled, (state, action) => {
        state.resumes = state.resumes?.map((resume) => {
          if (resume?.id === action.payload.id) {
            return { ...resume, status: action.payload.status };
          }
          return { ...resume };
        });
        state.resumesLoading = false;
      })

      // For Block Hospital User
      .addCase(BlockHospitalAction.fulfilled, (state, action) => {
        state.getUserLoading = false;
        state.user = action.payload.response;
      })

      // For Unblock Hospital User
      .addCase(UnblockHospitalAction.fulfilled, (state, action) => {
        state.user = action.payload.response;
        state.getUserLoading = false;
      })

      // Single Professional User's Tabs

      // For Get Holidays Data For Professional User
      .addCase(GetProfessionalUserHolidays.pending, (state) => {
        state.holidays = [];
        state.holidaysLoading = true;
      })
      .addCase(GetProfessionalUserHolidays.fulfilled, (state, action) => {
        state.holidays = action.payload.response;
        state.holidaysLoading = false;
      })
      .addCase(GetProfessionalUserHolidays.rejected, (state) => {
        state.holidays = [];
        state.holidaysLoading = false;
      })

      // For Hospital Subscription
      .addCase(GetSubscriptionHospitalAction.pending, (state) => {
        state.Subscription = [];
        state.getSubscriptionLoading = true;
      })
      .addCase(GetSubscriptionHospitalAction.fulfilled, (state, action) => {
        state.Subscription = action.payload.response;
        state.getSubscriptionLoading = false;
      })
      .addCase(GetSubscriptionHospitalAction.rejected, (state) => {
        state.Subscription = [];
        state.getSubscriptionLoading = false;
      })

      // For Get address hospital
      .addCase(GetHospitalAddressAction.pending, (state) => {
        state.address = null;
        state.addressLoading = true;
      })
      .addCase(GetHospitalAddressAction.fulfilled, (state, action) => {
        state.address = action.payload.response;
        state.addressLoading = false;
      })
      .addCase(GetHospitalAddressAction.rejected, (state) => {
        state.address = null;
        state.addressLoading = false;
      })

      .addCase(EditHospitalAddressAction.fulfilled, (state, action) => {
        state.address = action.payload.response;
        state.addressLoading = false;
      })

  },
});

export default userSlice.reducer;

import { createAsyncThunk } from "@reduxjs/toolkit";
import { getCms, updateCms } from "store/services/cms";
import { handleAuthError } from "utils/handleAuthError";
import { openToast } from "store/slices/common/toast.slice";

//Get All Cms
export const GetAllCmsAction = createAsyncThunk(
  "cms/getAllCms",
  async (payload, { rejectWithValue, fulfillWithValue }) => {
    try {
      const res = await getCms(payload);
      if (res.status === 1) {
        return fulfillWithValue(res);
      }
      return rejectWithValue(res);
    } catch (error) {
      handleAuthError(error?.response?.status);
      return rejectWithValue(error);
    }
  }
);

//Update Cms
export const UpdateCmsAction = createAsyncThunk(
  "cms/updateCms",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    try {
      const res = await updateCms(payload);
      if (res.status === 1) {
        dispatch(openToast({ type: "success", message: res?.message }));
        return fulfillWithValue(res);
      }
      dispatch(openToast({ type: "error", message: res?.message }));
      return rejectWithValue(res);
    } catch (error) {
      dispatch(openToast({ type: "error", message: error?.response?.data ? error?.response?.data?.message : error?.message }));
      handleAuthError(error?.response?.status);
      return rejectWithValue(error);
    }
  }
);

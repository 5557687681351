import { createSlice } from "@reduxjs/toolkit";
import {
  AddFaqAction,
  DeleteFaqAction,
  GetAllEmailsAction,
  GetAllFaqsAction,
  GetAllFaqsVotesAction,
  UpdateFaqAction,
  UpdateVisiblityFaqAction,
  GetAllChatListAction,
  UpdateStatusAction,
  AddChatAction,
  GetUserMessagesAction,
  AddMessageAction,
  DeleteMessageAction,
  FinishChatAction,
} from "store/middlewares/HelpAndSupport";

const initalVal =
{
  faqs: [],
  faqsLoading: false,
  votes: [],
  votesLoading: false,
  emails: [],
  emailsLoading: false,
  userchat: [],
  userchatLoading: false,
  chatList: [],
  chatListLoading: false,
  addChat: {},
  addChatLoading: false,
  getUserMessages: [],
  getUserMessagesLoading: false,

}

export const helpAndSupportSlice = createSlice({
  name: "helpAndSupport",
  initialState: initalVal,
  reducers: {
    resetReducer: (state) => {
      state.addChat = {}
      state.addChatLoading = false;
      state.getUserMessages = [];
      state.getUserMessagesLoading = false;
    }
  },
  extraReducers(builder) {
    builder
      // For Fetch All FAQs
      .addCase(GetAllFaqsAction.pending, (state) => {
        state.faqsLoading = state.faqs.length > 0 ? false : true;
        state.faqs = [];
      })
      .addCase(GetAllFaqsAction.fulfilled, (state, action) => {
        state.faqsLoading = false;
        state.faqs = action.payload.response;
      })
      .addCase(GetAllFaqsAction.rejected, (state) => {
        state.faqsLoading = false;
        state.faqs = [];
      })

      // For Fetch All FAQ Votes
      .addCase(GetAllFaqsVotesAction.pending, (state) => {
        state.votesLoading = true;
        state.votes = [];
      })
      .addCase(GetAllFaqsVotesAction.fulfilled, (state, action) => {
        state.votesLoading = false;
        state.votes = action.payload;
      })
      .addCase(GetAllFaqsVotesAction.rejected, (state) => {
        state.votesLoading = false;
        state.votes = [];
      })

      // For Add FAQ
      .addCase(AddFaqAction.fulfilled, (state, action) => {
        state.faqsLoading = false;
        state.faqs = [action.payload.response, ...state.faqs];
      })

      // For Update FAQ
      .addCase(UpdateFaqAction.fulfilled, (state, action) => {
        state.faqsLoading = false;
        state.faqs = state.faqs.map((faq) =>
          faq.id == action.payload.response.id ? action.payload.response : faq
        );
      })

      // For Delete FAQ
      .addCase(DeleteFaqAction.fulfilled, (state, action) => {
        let { id } = action.payload;
        let tempArray = state.faqs?.filter((faq) => faq.id !== id);
        state.faqsLoading = false;
        state.faqs = tempArray;
      })

      // For Update Visibility
      .addCase(UpdateVisiblityFaqAction.fulfilled, (state, action) => {
        state.faqsLoading = false;
        state.faqs = state.faqs.map((faq) =>
          faq.id === action.payload.id
            ? { ...faq, isVisible: !faq.isVisible }
            : faq
        );
      })

      // For Fetch Emails
      .addCase(GetAllEmailsAction.pending, (state) => {
        state.emailsLoading = state.emails.length > 0 ? false : true;
        state.emails = [];
      })
      .addCase(GetAllEmailsAction.fulfilled, (state, action) => {
        state.emailsLoading = false;
        state.emails = action.payload.response;
      })
      .addCase(GetAllEmailsAction.rejected, (state) => {
        state.emailsLoading = false;
        state.emails = [];
      })

      // For Update Emails
      .addCase(UpdateStatusAction.fulfilled, (state, action) => {
        let id = action.payload.id;
        state.emails = state.emails.map((email) => {
          if (email.id === id) {
            return { ...email, isArchived: true, status: "resolve" };
          }
          return { ...email };
        });
      })

      // For Fetch h&s chat list
      .addCase(GetAllChatListAction.pending, (state) => {
        state.chatListLoading = true;
        state.chatList = [];
      })
      .addCase(GetAllChatListAction.fulfilled, (state, action) => {
        state.chatListLoading = false;
        state.chatList = action.payload.response;
      })
      .addCase(GetAllChatListAction.rejected, (state) => {
        state.chatListLoading = false;
        state.chatList = [];
      })

      // For Add Chat
      .addCase(AddChatAction.pending, (state) => {
        state.addChatLoading = true;
        state.addChat = {};
      })
      .addCase(AddChatAction.fulfilled, (state, action) => {
        state.addChatLoading = false;
        state.addChat = action.payload.response;
      })
      .addCase(AddChatAction.rejected, (state) => {
        state.addChatLoading = false;
        state.addChat = {};
      })

      // For Get Messages
      .addCase(GetUserMessagesAction.pending, (state) => {
        state.getUserMessagesLoading = true;
        state.getUserMessages = state.getUserMessages.length > 0 ? state.getUserMessages : [];
      })
      .addCase(GetUserMessagesAction.fulfilled, (state, action) => {
        state.getUserMessagesLoading = false;
        state.getUserMessages = action.payload.response.reverse();
      })
      .addCase(GetUserMessagesAction.rejected, (state) => {
        state.getUserMessagesLoading = false;
        state.getUserMessages = [];
      })


      // For Add Message
      .addCase(AddMessageAction.fulfilled, (state, action) => {
        if (Array.isArray(state.getUserMessages)) {
          state.getUserMessages.push(action.payload.response);
        } else {
          state.getUserMessages = [action.payload.response];
        }
      })

      // For delete Message
      .addCase(DeleteMessageAction.fulfilled, (state, action) => {
        state.addChat = {};
        state.chatList = state.chatList.filter((chat) => chat.id !== action.payload.id);
      })

      // For Finish Chat
      .addCase(FinishChatAction.fulfilled, (state) => {
        state.addChat = { ...state.addChat, isChatFinished: !state.addChat.isChatFinished, };
      })
  },
});

export const { resetReducer } =
  helpAndSupportSlice.actions;

export default helpAndSupportSlice.reducer;

import { createSlice } from "@reduxjs/toolkit";
import {GetAllDynamicMenu,AddDynamicMenuAction,DeleteDynamicMenuAction,UpdateDynamicMenuAction} from "store/middlewares/dynamicMenu";

// isDeleteMeFulFilled
export const dynamicMenuSlice = createSlice({
  name: "dynamicMenu",
  initialState: {
    dynamicMenuLoading: false,
    dynamicMenu: [],
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(GetAllDynamicMenu.pending, (state) => {
        state.dynamicMenuLoading = true;
        state.dynamicMenu = [];
      })
      .addCase(GetAllDynamicMenu.fulfilled, (state, action) => {
        state.dynamicMenuLoading = false;
        state.dynamicMenu = action.payload.response;
      })
      .addCase(GetAllDynamicMenu.rejected, (state) => {
        state.dynamicMenuLoading = false;
        state.dynamicMenu = [];
      })
      // Delete Job Title
      .addCase(DeleteDynamicMenuAction.fulfilled, (state, action) => {
        let { id } = action.payload;
        let tempArray = state.dynamicMenu?.filter((title) => title.id !== id);
        state.dynamicMenuLoading = false;
        state.dynamicMenu = tempArray;
      })
      //Update Job Title
      .addCase(UpdateDynamicMenuAction.fulfilled, (state, action) => {
        let {
          data: { id, ...rest },
        } = action.payload.response;
        let tempArray = state.dynamicMenu?.map((title) => {
          
          if (title.id === id) {
            return { ...title, ...rest };
          }
          return { ...title };
        });
        state.dynamicMenuLoading = false;
        state.dynamicMenu = tempArray;
      })
      //Add Job Title
      .addCase(AddDynamicMenuAction.fulfilled, (state, action) => {
        state.dynamicMenuLoading = false;
        state.dynamicMenu = [...state.dynamicMenu, action.payload.response];
      })

      // .addCase(UpdateVisiblitySpecialityAction.fulfilled, (state, action) => {
      //   state.dynamicMenuLoading = false;
      //   let { id } = action.payload;
      //   let tempArray = state.dynamicMenu?.map((title) => {
      //     if (title.id === id) {
      //       return { ...title, isVisible: !title.isVisible };
      //     }
      //     return { ...title };
      //   });
      //   state.dynamicMenu = tempArray;
      // });
  },
});

export default dynamicMenuSlice.reducer;

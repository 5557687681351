import { createSlice } from "@reduxjs/toolkit";
import {
  AddBannerAction,
  DeleteBannerAction,
  GetAllBannersAction,
  UpdateBannerAction,
  UpdateVisiblityBannerAction,
} from "store/middlewares/banner";

export const bannersSlice = createSlice({
  name: "banners",
  initialState: {
    bannersLoading: false,
    banners: [],
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(GetAllBannersAction.pending, (state) => {
        state.bannersLoading = true;
        state.banners = [];
      })
      .addCase(GetAllBannersAction.fulfilled, (state, action) => {
        state.bannersLoading = false;
        state.banners = action.payload.response;
      })
      .addCase(GetAllBannersAction.rejected, (state) => {
        state.bannersLoading = false;
        state.banners = [];
      })
      // Delete banner
      .addCase(DeleteBannerAction.fulfilled, (state, action) => {
        let { id } = action.payload;
        let tempArray = state.banners?.filter((title) => title.id !== id);
        state.bannersLoading = false;
        state.banners = tempArray;
      })
      //Update banner
      .addCase(UpdateBannerAction.fulfilled, (state, action) => {
        let {
          response: { id, ...rest },
        } = action.payload;

        let tempArray = state.banners?.map((title) => {
          if (title.id === id) {
            return { ...title, ...rest, bannerSignedUrl: rest.signedUrl };
          }
          return { ...title };
        });
        state.bannersLoading = false;
        state.banners = tempArray;
      })
      //Add banner
      .addCase(AddBannerAction.fulfilled, (state, action) => {
        let tempObj = {
          ...action.payload.response,
          bannerSignedUrl: action.payload.response.signedUrl,
        };
        state.bannersLoading = false;
        state.banners = [tempObj, ...state.banners];
      })


      //Update Visiblity banner
      .addCase(UpdateVisiblityBannerAction.fulfilled, (state, action) => {
        state.bannersLoading = false;
        let { id } = action.payload;
        let tempArray = state.banners?.map((title) => {
          if (title.id === id) {
            return { ...title, isVisible: !title.isVisible };
          }
          return { ...title };
        });
        state.banners = tempArray;
      });
  },
});

export default bannersSlice.reducer;

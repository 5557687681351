import { Eye, EyeOff } from "lucide-react";
import React, { useState } from "react";

const PasswordInput = ({
  onChange,
  onBlur,
  value,
  error,
  errorMsg,
  placeholder,
  label,
  className,
  name,
  inputClassName,
  labelClassName,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className={className}>
      <label
        htmlFor={name}
        className={`inline-block mb-2 text-base font-medium ${labelClassName}`}
      >
        {label}
      </label>
      <br />
      <div className="relative">
        <input
          type={showPassword ? "text" : "password"}
          name={name}
          id={name}
          placeholder={placeholder}
          className={`form-input focus:outline-none focus:border-custom-500 disabled:bg-slate-100 disabled:border-slate-300 disabled:text-slate-500 placeholder:text-slate-400 ${
            error ? "border-red-500" : "border-slate-200"
          }  ${inputClassName}`}
          onChange={(e) => {
            if (onChange) {
              onChange(e);
            }
          }}
          onBlur={(e) => {
            if (onBlur) {
              onBlur(e);
            }
          }}
          value={value}
          style={{ paddingRight: "40px" }}
        />
        <button
          type="button"
          className="absolute right-[2px] top-[8px] px-[10px]"
          onClick={handleTogglePasswordVisibility}
        >
          {showPassword ? <Eye className="w-5" /> : <EyeOff className="w-5" />}
        </button>
      </div>
      {error && (
        <div id="password-error" className="mt-1 text-sm text-red-500">
          {errorMsg}
        </div>
      )}
    </div>
  );
};

export default PasswordInput;

import React, { useEffect } from "react";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { FieldArray, FormikProvider, useFormik } from "formik";
import {
  SubmitBtn,
  Input,
  SelectComponent,
  CustomLoader,
} from "components/atoms";
// import { Reason } from "utils/validation/AddJobCancellation";
import {
  EditHospitalAddressAction,
  // BlockHospitalAction,
  GetHospitalAddressAction,
} from "store/middlewares/users/hospitals";
import { Grid } from "@mui/material";
import { GetAllCountriesAction } from "store/middlewares/countries";
import { GetAllProvincesAction } from "store/middlewares/provinces";
import { GetAllCitiesAction } from "store/middlewares/cities";
import { CircleX, Plus } from "lucide-react";
import { GetAllSpecialitiesAction } from "store/middlewares/specialities";
import {
  addressType,
  city,
  country,
  firstAddress,
  Landmark,
  Name,
  secondAddress,
  Speciality,
  state,
  unitId,
  zipCode,
} from "utils/validation/AddHospital";

function ModalEditAddress({ id }) {
  const dispatch = useDispatch();

  const {
    countries: { countries },
    provinces: { provinces },
    cities: { cities },
    specialities: { specialities },
    user: { addressLoading, address },
  } = useSelector((state) => state);

  useEffect(() => {
    dispatch(GetAllCountriesAction());
    dispatch(GetAllProvincesAction());
    dispatch(GetAllCitiesAction());
    dispatch(GetAllSpecialitiesAction());
    dispatch(GetHospitalAddressAction({ id }));
  }, [dispatch]);

  const intitalVal = {
    addressDetails: {
      addressTypeId: address?.addressTypeId,
      firstAddress: address?.firstAddress,
      secondAddress: address?.secondAddress,
      landmark: address?.landmark,
      unitId: address?.unitId,
      countryId: address?.countryId,
      cityId: address?.cityId,
      stateId: address?.stateId,
      zipCode: address?.zipCode,
    },
    floorDetails:
      address?.floorDetails?.length > 0
        ? address?.floorDetails?.map((floor) => {
            return {
              name: floor?.name,
              speciality: floor?.floorSpecialities?.map((spec) => {
                return spec?.speciality;
              }),
            };
          })
        : [{ speciality: [] }],
  };

  const AddAddressSchema = Yup.object({
    addressDetails: Yup.object().shape({
      addressTypeId: addressType,
      firstAddress: firstAddress,
      secondAddress: secondAddress,
      landmark: Landmark,
      unitId: unitId,
      cityId: city,
      stateId: state,
      countryId: country,
      zipCode: zipCode,
    }),
    floorDetails: Yup.array().of(
      Yup.object().shape({
        name: Name,
        speciality: Speciality,
      })
    ),
  });

  const handleSubmit = (values) => {
    let payload = { id: address?.id};
    let deleteKeyArray = ["addressDetails", "floorDetails"];
    if (values.addressDetails.secondAddress === "") {
      values.addressDetails.secondAddress = null;
    }
    deleteKeyArray?.forEach((key) => {
      payload = { ...payload, [`${key}`]: values[`${key}`]};
    });
    dispatch(EditHospitalAddressAction(payload));
  };

  const formik = useFormik({
    initialValues: intitalVal,
    validationSchema: AddAddressSchema,
    enableReinitialize: true,
    onSubmit: handleSubmit,
  });

  return (
    <>
      {!addressLoading ? (
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={2}>
            <Grid item md={6} xs={12}>
              <Input
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                inputClassName="rounded-md border-solid border border-zinc-400 mt-2 w-full px-2 py-2  focus:outline-0 focus:border-[#2684FF]"
                className={"px-0"}
                selectclassName={"mt-2"}
                label={"Address Type"}
                name="addressDetails.addressTypeId"
                placeholder="Select Address Type"
                error={
                  formik.errors.addressDetails?.addressTypeId &&
                  formik.touched.addressDetails?.addressTypeId
                }
                errorMsg={formik.errors.addressDetails?.addressTypeId}
                value={formik.values.addressDetails?.addressTypeId}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <Input
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                inputClassName="rounded-md border-solid border border-zinc-400 mt-2 w-full px-2 py-2  focus:outline-0 focus:border-[#2684FF]"
                className={"px-0"}
                label={"Address 1"}
                name="addressDetails.firstAddress"
                value={formik.values.addressDetails?.firstAddress}
                placeholder="Enter Address 1"
                error={
                  formik.errors.addressDetails?.firstAddress &&
                  formik.touched.addressDetails?.firstAddress
                }
                errorMsg={formik.errors.addressDetails?.firstAddress}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <Input
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                inputClassName="rounded-md border-solid border border-zinc-400 mt-2 w-full px-2 py-2  focus:outline-0 focus:border-[#2684FF]"
                className={"px-0"}
                label={"Address 2"}
                name="addressDetails.secondAddress"
                value={formik.values.addressDetails?.secondAddress}
                placeholder="Enter Address 2 "
                error={
                  formik.errors.addressDetails?.secondAddress &&
                  formik.touched.addressDetails?.secondAddress
                }
                errorMsg={formik.errors.addressDetails?.secondAddress}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <Input
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                inputClassName="rounded-md border-solid border border-zinc-400 mt-2 w-full px-2 py-2  focus:outline-0 focus:border-[#2684FF]"
                className={"px-0"}
                label={"landmark"}
                name="addressDetails.landmark"
                placeholder="Select Job Title"
                value={formik.values.addressDetails?.landmark}
                error={
                  formik.errors.addressDetails?.landmark &&
                  formik.touched.addressDetails?.landmark
                }
                errorMsg={formik.errors.addressDetails?.landmark}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <Input
                label={"Unit ID"}
                type="text"
                name="addressDetails.unitId"
                placeholder="Enter Unit ID"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.errors.addressDetails?.unitId &&
                  formik.touched.addressDetails?.unitId
                }
                errorMsg={formik.errors.addressDetails?.unitId}
                value={formik.values.addressDetails?.unitId}
                inputClassName="rounded-md border-solid border border-zinc-400 mt-2 w-full px-2 py-2  focus:outline-0 focus:border-[#2684FF]"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <SelectComponent
                className={"px-0"}
                selectclassName={"mt-2"}
                label={"Country"}
                name="addressDetails?.countryId"
                placeholder="Select Country"
                options={countries?.map((item) => {
                  return { label: item?.name, value: item?.id };
                })}
                error={
                  formik.errors.addressDetails?.countryId &&
                  formik.touched.addressDetails?.countryId
                }
                errorMsg={formik.errors.addressDetails?.countryId}
                value={formik.values.addressDetails?.countryId}
                onChange={(selectedOption) => {
                  formik.setFieldValue(
                    "addressDetails.countryId",
                    selectedOption ? selectedOption.value : ""
                  );
                }}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <SelectComponent
                className={"px-0"}
                selectclassName={"mt-2"}
                label={"State"}
                name="addressDetails.stateId"
                disabled={!formik.values.addressDetails.countryId}
                placeholder="Select State"
                options={provinces
                  ?.filter(
                    (province) =>
                      province.country_id === formik.values.addressDetails.countryId
                  )
                  ?.map((prov) => {
                    return { label: prov.name, value: prov.id };
                  })}
                error={
                  formik.errors.addressDetails?.stateId &&
                  formik.touched.addressDetails?.stateId
                }
                errorMsg={formik.errors.addressDetails?.stateId}
                value={formik.values.addressDetails?.stateId}
                onChange={(selectedOption) =>
                  formik.setFieldValue(
                    "stateId",
                    selectedOption ? selectedOption.value : ""
                  )
                }
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <SelectComponent
                className={"px-0"}
                selectclassName={"mt-2"}
                label={"City"}
                name="addressDetails.cityId"
                disabled={!formik.values.addressDetails.stateId}
                options={cities
                  ?.filter(
                    (province) => province.province_id === formik.values.addressDetails.stateId
                  )
                  ?.map((prov) => {
                    return { label: prov.name, value: prov.id };
                  })}
                placeholder="Select City"
                error={
                  formik.errors.addressDetails?.cityId &&
                  formik.touched.addressDetails?.cityId
                }
                errorMsg={formik.errors.addressDetails?.cityId}
                value={formik.values.addressDetails?.cityId}
                onChange={(selectedOption) =>
                  formik.setFieldValue(
                    "cityId",
                    selectedOption ? selectedOption.value : ""
                  )
                }
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <Input
                type="text"
                label={"ZIP Code"}
                name="addressDetails.zipCode"
                placeholder="Enter Facility"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.addressDetails?.zipCode}
                error={
                  formik.errors.addressDetails?.zipCode &&
                  formik.touched.addressDetails?.zipCode
                }
                errorMsg={formik.errors.addressDetails?.zipCode}
                inputClassName="rounded-md border-solid border border-zinc-400 mt-2 w-full px-2 py-2  focus:outline-0 focus:border-[#2684FF]"
              />
            </Grid>
            <Grid item xs={12}>
              <FormikProvider value={formik}>
                <FieldArray name="floorDetails">
                  {({ push, remove }) => (
                    <Grid container spacing={2}>
                      <Grid
                        item
                        md={12}
                        xs={12}
                        className="flex justify-between items-center pb-5"
                      >
                        <h5 className="text-16 font-bold underline">
                          Add Floor :
                        </h5>
                        <button
                          type="button"
                          className="flex items-center justify-center cursor-pointer size-8 bg-black rounded-[50%]"
                          onClick={() =>
                            push({
                              name: `${formik.values.floorDetails.length + 1}`,
                              speciality: [],
                            })
                          }
                        >
                          <Plus className="text-white" />
                        </button>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container spacing={5}>
                          {formik.values.floorDetails?.map((_, index) => {
                            return (
                              <Grid item xs={12} md={6} key={index}>
                                <div
                                  className={`p-5 bg-[#eff6ff] mb-2 rounded-xl `}
                                >
                                  <div className={`flex justify-between`}>
                                    <label className="font-medium">
                                      Speciality
                                    </label>
                                    {index > 0 && (
                                      <CircleX
                                        className="text-[#F8585A]"
                                        onClick={() => remove(index)}
                                      />
                                    )}
                                  </div>

                                  <SelectComponent
                                    multiSelect={true}
                                    className={"px-0"}
                                    name={`floorDetails.${index}.speciality`}
                                    placeholder="Select Speciality"
                                    options={specialities?.map((ele) => {
                                      return {
                                        label: ele?.speciality,
                                        value: ele?.id,
                                      };
                                    })}
                                    error={Boolean(
                                      formik.errors.floorDetails?.[index]
                                        ?.speciality &&
                                        formik.touched.floorDetails?.[index]
                                          ?.speciality
                                    )}
                                    errorMsg={
                                      formik.errors.floorDetails?.[index]
                                        ?.speciality &&
                                      formik.touched.floorDetails?.[index]
                                        ?.speciality
                                        ? formik.errors.floorDetails[index]
                                            .speciality
                                        : ""
                                    }
                                    value={
                                      formik.values.floorDetails[index]
                                        ?.speciality
                                    }
                                    onChange={(selectedOption) => {
                                      formik.setFieldValue(
                                        `floorDetails.${index}.speciality`,
                                        selectedOption
                                          ? selectedOption.map(
                                              (option) => option.value
                                            )
                                          : []
                                      );
                                      formik.setFieldTouched(
                                        `floorDetails.${index}.speciality`,
                                        true
                                      );
                                    }}
                                  />
                                </div>
                              </Grid>
                            );
                          })}
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                </FieldArray>
              </FormikProvider>
            </Grid>
            <Grid item md={12} xs={12}>
              <SubmitBtn title={"Update"} />
            </Grid>
          </Grid>
        </form>
      ) : (
        <CustomLoader />
      )}
    </>
  );
}

export default ModalEditAddress;

import { createSlice } from "@reduxjs/toolkit";
import { GetMeAction, UpdateMeAction } from "store/middlewares/me";

// isDeleteMeFulFilled
export const meSlice = createSlice({
  name: "me",
  initialState: {
    meLoading: false,
    me: null,
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(GetMeAction.pending, (state) => {
        state.meLoading = true;
        state.me = null;
      })
      .addCase(GetMeAction.fulfilled, (state, action) => {
        state.meLoading = false;
        state.me = action.payload.response;
      })
      .addCase(GetMeAction.rejected, (state) => {
        state.meLoading = false;
        state.me = null;
      })
      .addCase(UpdateMeAction.fulfilled, (state, action) => {
        const {
          payload: { data },
        } = action;
        let { me } = state;
        let tempObj = { ...me, ...data };
        state.me = tempObj;
      });
  },
});

export default meSlice.reducer;

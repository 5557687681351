import { createSlice } from "@reduxjs/toolkit";
import {
  AddDeleteReasonAction,
  DeleteDeleteReasonAction,
  GetAllDeleteReasonsAction,
  UpdateDeleteReasonAction,
  UpdateVisiblityDeleteReasonAction,
} from "store/middlewares/deleteReason";

// isDeleteMeFulFilled
export const deleteReasonsSlice = createSlice({
  name: "deleteReason",
  initialState: {
    deleteReasonsLoading: false,
    deleteReasons: [],
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(GetAllDeleteReasonsAction.pending, (state) => {
        state.deleteReasonsLoading = true;
        state.deleteReasons = [];
      })
      .addCase(GetAllDeleteReasonsAction.fulfilled, (state, action) => {
        state.deleteReasonsLoading = false;
        state.deleteReasons = action.payload.response;
      })
      .addCase(GetAllDeleteReasonsAction.rejected, (state) => {
        state.deleteReasonsLoading = false;
        state.deleteReasons = [];
      })
      // Delete  Delete Reason
      .addCase(DeleteDeleteReasonAction.fulfilled, (state, action) => {
        let { id } = action.payload;
        let tempArray = state.deleteReasons?.filter((title) => title.id !== id);
        state.deleteReasonsLoading = false;
        state.deleteReasons = tempArray;
      })
      //Update  Delete Reason
      .addCase(UpdateDeleteReasonAction.fulfilled, (state, action) => {
        let {
          response: { id, ...rest },
        } = action.payload;
        let tempArray = state.deleteReasons?.map((title) => {
          if (title.id === id) {
            return { ...title, ...rest };
          }
          return { ...title };
        });
        state.deleteReasonsLoading = false;
        state.deleteReasons = tempArray;
      })
      //Add  Delete Reason
      .addCase(AddDeleteReasonAction.fulfilled, (state, action) => {
        state.deleteReasonsLoading = false;
        state.deleteReasons = [...state.deleteReasons, action.payload.response];
      })

      //Update Visiblity Delete Reason
      .addCase(
        UpdateVisiblityDeleteReasonAction.fulfilled,
        (state, action) => {
          state.deleteReasonsLoading = false;
          let { id } = action.payload;
          let tempArray = state.deleteReasons?.map((Reasons) => {
            if (Reasons.id === id) {
              return { ...Reasons, isVisible: !Reasons.isVisible };
            }
            return { ...Reasons };
          });
          state.deleteReasons = tempArray;
        }
      );
  },
});

export default deleteReasonsSlice.reducer;

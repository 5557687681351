import React from "react";

const SubmitBtn = ({ title, className }) => {
  return (
    <div className={`py-2 text-end ${className}`}>
      <button
        type="submit"
        className="text-white btn px-3 bg-[#0ca7dfd3] hover:text-white hover:bg-[#0CA8DF] p-2 ml-3 rounded-lg"
      >
        {title}
      </button>
    </div>
  );
};

export default SubmitBtn;

import { createSlice } from "@reduxjs/toolkit";
import {
  ForgotPasswordAction,
  LoginAdminAction,
  LogOutAdminAction,
  ResetPasswordAction,
  ValidateTokenAdmin,
} from "store/middlewares/session/auth";
import LocalstorageService from "utils/localstorage-services";
import { encryptAndStoreToken } from "utils/CryptoJS";

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    loginMessage: "",
    forgotPass: false,
    resetPass: false,
    isTokenValid: true,
  },
  extraReducers(builder) {
    builder
      .addCase(LoginAdminAction.pending, (state) => {
        state.loginMessage = "";
      })
      .addCase(LoginAdminAction.fulfilled, (state, action) => {
        let { response } = action.payload;
        const localStorage = {
          user: { ...response, token: encryptAndStoreToken(response?.token) },
        };
        LocalstorageService.loginUser(localStorage);
        state.loginMessage = action.payload.message;
        window.location = "/admin/dashboard";
      })
      .addCase(LogOutAdminAction.fulfilled, (state) => {
        state.loginMessage = "";
        LocalstorageService.removeFromLocalStorage("aboutcare-admin");
        window.location = "/";
      })
      .addCase(LoginAdminAction.rejected, (state, action) => {
        state.loginMessage = action.payload.message;
      })
      .addCase(ForgotPasswordAction.fulfilled, (state) => {
        state.forgotPass = true;
        state.resetPass = false;
      })
      .addCase(ResetPasswordAction.fulfilled, (state) => {
        state.forgotPass = false;
        state.resetPass = true;
      })
      .addCase(ValidateTokenAdmin.fulfilled, (state, action) => {
        state.isTokenValid = !action.payload.response.isTokenExpired;
      })
      .addCase(ValidateTokenAdmin.rejected, (state) => {
        state.isTokenValid = false;
      });
  },
});

export default authSlice.reducer;

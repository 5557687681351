import React from "react";
import logo from "assets/images/logoWithoutName.png";
import { Dropdown } from "./Dropdown";
import Swal from "sweetalert2";
import { Logout } from "@mui/icons-material";
import { LockKeyhole, User2 } from "lucide-react";
import { useDispatch, useSelector } from "react-redux";
import { LogOutAdminAction } from "store/middlewares/session/auth";

const ProfileMenu = () => {
  const { me } = useSelector((state) => state?.me);
  const dispatch = useDispatch();
  const logutmodel = () => {
    Swal.fire({
      title: "Are you sure you want to logout ?",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
      customClass: {
        title: "Swal-title-class",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(LogOutAdminAction());
      }
    });
  };
  return (
    <Dropdown className="relative flex items-center h-header">
      <Dropdown.Trigger
        type="button"
        className="inline-block p-0 transition-all duration-200 ease-linear rounded-full text-topbar-item dropdown-toggle btn"
        id="dropdownMenuButton"
        data-bs-toggle="dropdown"
      >
        <div>
          <img src={logo} alt="" className="w-[60px] pointer-events-none " />
        </div>
      </Dropdown.Trigger>
      <Dropdown.Content
        placement="right-end"
        className="absolute z-50 p-4 text-left bg-white rounded-md shadow-md  border !top-4 dropdown-menu min-w-[14rem]"
        aria-labelledby="dropdownMenuButton"
      >
        <div className="flex items-center gap-3 mb-3">
          <div className="relative inline-block shrink-0">
            <div>
              <img
                src={logo}
                alt=""
                className="w-[60px] pointer-events-none "
              />
            </div>
          </div>
          <div>
            <p className="otp-color">{me?.name}</p>
          </div>
        </div>
        <ul>
          <li>
            <a
              href={"/admin/profile"}
              className="block pr-4 py-1.5 text-base font-medium transition-all duration-200 ease-linear text-slate-600 dropdown-item hover:text-custom-500 focus:text-custom-500 dark:text-zink-200 dark:hover:text-custom-500 dark:focus:text-custom-500"
            >
              <User2 className="inline-block size-4 mr-2 mb-1"></User2> 
              Profile
            </a>
          </li>
          <li>
            <a
              href={"/admin/ChangePass"}
              className="block pr-4 py-1.5 text-base font-medium transition-all duration-200 ease-linear text-slate-600 dropdown-item hover:text-custom-500 focus:text-custom-500 dark:text-zink-200 dark:hover:text-custom-500 dark:focus:text-custom-500"
            >
              <LockKeyhole className="inline-block size-4 mr-2 mb-1"></LockKeyhole>
              Change Password
            </a>
          </li>
          <li className="pt-2 mt-2 border-t border-slate-200">
            <a
              className="flex items-center  otp-color cursor-pointer pr-4 py-1.5 text-base font-medium transition-all duration-200 ease-linear text-slate-600 dropdown-item hover:text-custom-500 focus:text-custom-500"
              onClick={logutmodel}
            >
              <Logout className="inline-block size-4 mr-2" fontSize="small" />
              Sign Out
            </a>
          </li>
        </ul>
      </Dropdown.Content>
    </Dropdown>
  );
};

export default ProfileMenu;

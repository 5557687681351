import * as Yup from "yup";

export const ImageFile = Yup.mixed()
.required("Banner image is required")
.test("fileSize", "Please select a valid image file with size up to 3MB", (value) => {
  return value && value.size <= 3 * 1024 * 1024; // 3MB limit
});
export const FileVideoType = Yup.mixed().required("Video is required").test('fileSize', 'Video size must be less than 10MB', (file) => file && file.size <= 10 * 1024 * 1024);
export const BannerType = Yup.string().required("Banner Type is required");
export const StartDate = Yup.string().required("Start date is required");
export const EndDate = Yup.string().required("End Date is required");

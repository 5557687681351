import { createSlice } from "@reduxjs/toolkit";
import {
  AddSkillAction,
  DeleteSkillAction,
  GetAllSkillsAction,
  UpdateSkillAction,
  UpdateVisiblitySkillAction,
} from "store/middlewares/skills";

export const skillsSlice = createSlice({
  name: "skills",
  initialState: {
    skillsLoading: false,
    skills: [],
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(GetAllSkillsAction.pending, (state) => {
        state.skillsLoading = true;
        state.skills = [];
      })
      .addCase(GetAllSkillsAction.fulfilled, (state, action) => {
        state.skillsLoading = false;
        state.skills = action.payload.response.data
          ? action.payload.response.data
          : action.payload.response;
      })
      .addCase(GetAllSkillsAction.rejected, (state) => {
        state.skillsLoading = false;
        state.skills = [];
      })
      // Delete Job Title
      .addCase(DeleteSkillAction.fulfilled, (state, action) => {
        let { id } = action.payload;
        let tempArray = state.skills?.filter((title) => title.id !== id);
        state.skillsLoading = false;
        state.skills = tempArray;
      })
      //Update Job Title
      .addCase(UpdateSkillAction.fulfilled, (state, action) => {
        let obj = action.payload.response.data
          ? action.payload.response.data
          : action.payload.response;
        let { id, ...rest } = obj;
        let tempArray = state.skills?.map((title) => {
          if (title.id === id) {
            return { ...title, ...rest };
          }
          return { ...title };
        });
        state.skillsLoading = false;
        state.skills = tempArray;
      })
      //Add Job Title
      .addCase(AddSkillAction.fulfilled, (state, action) => {
        state.skillsLoading = false;
        let obj = action.payload.response.newcode
          ? action.payload.response.newcode
          : action.payload.response;
        state.skills = [...state.skills, obj];
      })
      //Add Job Title
      .addCase(UpdateVisiblitySkillAction.fulfilled, (state, action) => {
        state.skillsLoading = false;
        let { id } = action.payload;
        let tempArray = state.skills?.map((title) => {
          if (title.id === id) {
            return { ...title, isVisible: !title.isVisible };
          }
          return { ...title };
        });
        state.skills = tempArray;
      });
  },
});

export default skillsSlice.reducer;

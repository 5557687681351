// src/App.js
import React from "react";
import "./App.css";
import { Route, Routes } from "react-router-dom";
import "@fontsource/poppins";
import "assets/scss/themes.scss";
import { AdminModal, ToastWrapper } from "components/atoms";
import AdminNavigation from "navigation/adminNavigation";
import AdminAuthNavigation from "navigation/adminAuth";

const App = () => {
  return (
    <>
      <AdminModal />
      <ToastWrapper />
      <Routes>
        <Route path="/admin/*" element={<AdminNavigation />} />
        <Route path="/*" element={<AdminAuthNavigation />} />
      </Routes>
    </>
  );
};

export default App;

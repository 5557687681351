import React from "react";
import Select from "react-select";

const SelectComponent = ({
  options,
  value,
  label,
  name,
  onChange,
  placeholder,
  error,
  errorMsg,
  labelclassName,
  selectclassName,
  defaultValue,
  className,
  disabled = false,
  dataChoices = true,
  isClearable = true,
  multiSelect = false,
  isSearchable = false,
}) => {
  let valueArray = [];
  if (multiSelect) {
    value?.forEach((val) => {
      valueArray?.push(options?.find((option) => option?.value === val));
    });
  }
  return (
    <div className={`p-5 py-2 ${className}`}>
      {typeof label === "string" ? (
        <>
          <label className={`font-medium ${labelclassName}`}>{label}</label>
          <br />
        </>
      ) : (
        label
      )}
      <Select
        isMulti={multiSelect}
        className={`select-css custom-select-container rounded-full ${selectclassName}`}
        classNamePrefix="react-select"
        data-choices={dataChoices}
        isClearable={isClearable}
        isDisabled={disabled}
        isSearchable={isSearchable}
        defaultValue={defaultValue}
        name={name}
        placeholder={placeholder}
        options={options}
        value={
          multiSelect
            ? valueArray
            : options?.find((option) => option?.value === value)
        }
        menuPortalTarget={document.body}
        menuPosition={"fixed"}
        styles={{
          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
          menu: (provided) => ({ ...provided, zIndex: 9999 }),
        }}
        onChange={onChange}
      />
      {error && <div className="text-red-500 text-sm mt-1">{errorMsg}</div>}
    </div>
  );
};

export default SelectComponent;

import React from "react";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { Input, SelectComponent, SubmitBtn } from "components/atoms";
import { Name, Access } from "utils/validation/AddRole";
import { AddRoleAction } from "store/middlewares/roles";

function ModalAddRoles() {
  const dispatch = useDispatch();

  const { access } = useSelector((state) => state.access);

  const intitalVal = {
    name: "",
    accessId: [],
  };

  const AddRoleSchema = Yup.object({ name: Name, accessId: Access });

  const handleSubmit = (values) => {
    dispatch(AddRoleAction(values));
  };

  const formik = useFormik({
    initialValues: intitalVal,
    validationSchema: AddRoleSchema,
    enableReinitialize: true,
    onSubmit: handleSubmit,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Input
        inputClassName="rounded-lg border border-solid border-zinc-400 w-full px-2 py-2 mt-2"
        label={"Name"}
        type="text"
        name="name"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.errors.name && formik.touched.name}
        errorMsg={formik.errors.name}
        value={formik.values.name}
        placeholder="Enter Name"
      />
      <SelectComponent
        className={"px-0"}
        label={"Access"}
        multiSelect={true}
        options={access?.map((country) => {
          return {
            label: country?.name,
            value: country?.id,
          };
        })}
        placeholder="Select Access"
        name={"accessId"}
        value={formik.values.accessId}
        error={formik.errors.accessId && formik.touched.accessId}
        errorMsg={formik.errors.accessId}
        onChange={(selectedOption) => {
          formik.setFieldValue(
            "accessId",
            selectedOption ? selectedOption.map(
              (option) => option.value
            ) : ""
          );
        }}
      />
      <SubmitBtn title={"Add"} />
    </form>
  );
}

export default ModalAddRoles;

import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  addJobTitle,
  deleteJobTitle,
  getJobTitle,
  updateJobTitle,
  updateJobTitleVisiblity,
} from "store/services/jobTitle";
import { closeAdminModal } from "store/slices/common/adminModal.slice";
import { openToast } from "store/slices/common/toast.slice";
import { handleAuthError } from "utils/handleAuthError";

export const GetAllJobTitleAction = createAsyncThunk(
  "jobTitle/getAllJobTitle",
  async (payload, { rejectWithValue, fulfillWithValue }) => {
    try {
      const res = await getJobTitle();
      if (res.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        return fulfillWithValue(res);
      }
      return rejectWithValue(res);
    } catch (error) {
      handleAuthError(error?.response?.status);
      return rejectWithValue(error);
    }
  }
);

export const DeleteJobTitleAction = createAsyncThunk(
  "jobTitle/deleteJobTitle",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const res = await deleteJobTitle(payload);
      if (res.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        dispatch(openToast({ type: "success", message: res?.message }));
        return fulfillWithValue(payload);
      }
      dispatch(openToast({ type: "error", message: res?.message }));
      return rejectWithValue(res);
    } catch (error) {
      dispatch(openToast({ type: "error", message: error?.response?.data ? error?.response?.data?.message : error?.message }));
      handleAuthError(error?.response?.status);
      return rejectWithValue(error);
    }
  }
);

export const UpdateVisiblityJobTitleAction = createAsyncThunk(
  "jobTitle/updateVisiblityJobTitle",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const res = await updateJobTitleVisiblity(payload);
      if (res.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        return fulfillWithValue(payload);
      }
      dispatch(openToast({ type: "error", message: res?.message }));
      return rejectWithValue(res);
    } catch (error) {
      dispatch(openToast({ type: "error", message: error?.response?.data ? error?.response?.data?.message : error?.message }));
      handleAuthError(error?.response?.status);
      return rejectWithValue(error);
    }
  }
);

export const UpdateJobTitleAction = createAsyncThunk(
  "jobTitle/updateJobTitle",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const res = await updateJobTitle(payload);
      if (res.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        dispatch(closeAdminModal());
        dispatch(openToast({ type: "success", message: res?.message }));
        return fulfillWithValue(res);
      }
      dispatch(openToast({ type: "error", message: res?.message }));
      return rejectWithValue(res);
    } catch (error) {
      dispatch(openToast({ type: "error", message: error?.response?.data ? error?.response?.data?.message : error?.message }));
      handleAuthError(error?.response?.status);
      return rejectWithValue(error);
    }
  }
);

export const AddJobTitleAction = createAsyncThunk(
  "jobTitle/addJobTitle",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const res = await addJobTitle(payload);
      if (res.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        dispatch(closeAdminModal());
        dispatch(openToast({ type: "success", message: res?.message }));
        return fulfillWithValue(res);
      }
      dispatch(openToast({ type: "error", message: res?.message }));
      return rejectWithValue(res);
    } catch (error) {
      dispatch(openToast({ type: "error", message: error?.response?.data ? error?.response?.data?.message : error?.message }));
      handleAuthError(error?.response?.status);
      return rejectWithValue(error);
    }
  }
);

import axiosClient from "utils/api";
const URI = "/admin/badges";

export const getBadges = async () => {
  const URL = `${URI}/badgeDesignation`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "get",
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred Get All Badges:", error);
    throw error;
  }
};

export const deleteBadges  = async (payload) => {
  const URL = `${URI}/badgeDesignation/${payload.id}`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "delete",
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred Delete Badges :", error);
    throw error;
  }
};

export const updateBadges  = async (payload) => {
  let { id, ...rest } = payload;
  const URL = `${URI}/badgeDesignation/${id}`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "put",
      data: rest,
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred Update Badges :", error);
    throw error;
  }
};

export const addBadges  = async (payload) => {
  const URL = `${URI}/badgeDesignation`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "post",
      data: payload,
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred Add Badges :", error);
    throw error;
  }
};

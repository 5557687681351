import { createSlice } from "@reduxjs/toolkit";
import {
  AddReportAction,
  DeleteReportAction,
  GetAllReportsAction,
  UpdateReportAction,
  UpdateVisiblityReportAction,
} from "store/middlewares/reports";

// isDeleteMeFulFilled
export const reportsSlice = createSlice({
  name: "reports",
  initialState: {
    reportsLoading: false,
    reports: [],
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(GetAllReportsAction.pending, (state) => {
        state.reportsLoading = true;
        state.reports = [];
      })
      .addCase(GetAllReportsAction.fulfilled, (state, action) => {
        state.reportsLoading = false;
        state.reports = action.payload.response;
      })
      .addCase(GetAllReportsAction.rejected, (state) => {
        state.reportsLoading = false;
        state.reports = [];
      })
      // Delete Job Title
      .addCase(DeleteReportAction.fulfilled, (state, action) => {
        let { id } = action.payload;
        let tempArray = state.reports?.filter((title) => title.id !== id);
        state.reportsLoading = false;
        state.reports = tempArray;
      })
      //Update Job Title
      .addCase(UpdateReportAction.fulfilled, (state, action) => {
        let {
          response: { id, ...rest },
        } = action.payload;
        let tempArray = state.reports?.map((title) => {
          if (title.id === id) {
            return { ...title, ...rest };
          }
          return { ...title };
        });
        state.reportsLoading = false;
        state.reports = tempArray;
      })
      //Add Job Title
      .addCase(AddReportAction.fulfilled, (state, action) => {
        state.reportsLoading = false;
        state.reports = [...state.reports, action.payload.response];
      })
      //Update Visiblity Job Title
      .addCase(UpdateVisiblityReportAction.fulfilled, (state, action) => {
        state.reportsLoading = false;
        let { id } = action.payload;
        let tempArray = state.reports?.map((title) => {
          if (title.id === id) {
            return { ...title, isVisible: !title.isVisible };
          }
          return { ...title };
        });
        state.reports = tempArray;
      });
  },
});

export default reportsSlice.reducer;

import { createSlice } from "@reduxjs/toolkit";
import {
  AddJobCancellationAction,
  DeleteJobCancellationAction,
  GetAllJobCancellationAction,
  UpdateJobCancellationAction,
  UpdateVisiblityJobCancellationAction,
} from "store/middlewares/jobCancellation";

// isDeleteMeFulFilled
export const jobCancellationSlice = createSlice({
  name: "jobCancellation",
  initialState: {
    jobCancellationsLoading: false,
    jobCancellations: [],
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(GetAllJobCancellationAction.pending, (state) => {
        state.jobCancellationsLoading = true;
        state.jobCancellations = [];
      })
      .addCase(GetAllJobCancellationAction.fulfilled, (state, action) => {
        state.jobCancellationsLoading = false;
        state.jobCancellations = action.payload.response;
      })
      .addCase(GetAllJobCancellationAction.rejected, (state) => {
        state.jobCancellationsLoading = false;
        state.jobCancellations = [];
      })
      // Delete Job Cancellation
      .addCase(DeleteJobCancellationAction.fulfilled, (state, action) => {
        let { id } = action.payload;
        let tempArray = state.jobCancellations?.filter((Cancellation) => Cancellation.id !== id);
        state.jobCancellationsLoading = false;
        state.jobCancellations = tempArray;
      })
      //Update Job Cancellation
      .addCase(UpdateJobCancellationAction.fulfilled, (state, action) => {
        let {
          response: { id, ...rest },
        } = action.payload;
        let tempArray = state.jobCancellations?.map((Cancellation) => {
          if (Cancellation.id === id) {
            return { ...Cancellation, ...rest };
          }
          return { ...Cancellation };
        });
        state.jobCancellationsLoading = false;
        state.jobCancellations = tempArray;
      })
      //Add Job Cancellation
      .addCase(AddJobCancellationAction.fulfilled, (state, action) => {
        state.jobCancellationsLoading = false;
        state.jobCancellations = [...state.jobCancellations, action.payload.response];
      })
      //Update Visiblity Job Cancellation
      .addCase(UpdateVisiblityJobCancellationAction.fulfilled, (state, action) => {
        state.jobCancellationsLoading = false;
        let { id } = action.payload;
        let tempArray = state.jobCancellations?.map((Cancellation) => {
          if (Cancellation.id === id) {
            return { ...Cancellation, isVisible: !Cancellation.isVisible };
          }
          return { ...Cancellation };
        });
        state.jobCancellations = tempArray;
      });
  },
});

export default jobCancellationSlice.reducer;

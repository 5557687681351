import React from "react";
import { useDispatch } from "react-redux";
import { ManageAllCredentialAddressAction } from "store/middlewares/credential";
import RejectSVGModel from "assets/images/RejectSVGModel.svg";
import ApprovedSVGModel from "assets/images/ApprovedSVGModel.svg";
import Swal from "sweetalert2";
import { DeleteButton, StatusPill, AddButton } from "..";
import checkAccess from "utils/checkAccess";
import { MapPinned } from "lucide-react";
// import { Grid } from "@mui/material";

const PhysicalAddressItem = ({ address }) => {
  const dispatch = useDispatch();
  return (
        <div
          className="border border-dashed card  border-custom-200 "
          key={address?.id}
        >
          <div className="pt-5 px-5 flex justify-between items-center">
            <MapPinned className="size-8 shrink-0 mr-3" />
            <div className="flex gap-2">
              {address?.isDefault && (
                <StatusPill
                  status={address?.isDefault}
                  statusText={"Default"}
                />
              )}
              <StatusPill
                status={address?.status}
                statusText={address?.status}
              />
            </div>
          </div>
          <div className="card-body break-all">
            <h6 className="text-base capitalize">
              {[
                address.addressTypeId,
                address.firstAddress,
                address.secondAddress,
                address.city,
                address.state,
                address.country,
                address.zipCode,
              ]
                .filter(Boolean)
                .join(", ")}
            </h6>
            <div className="flex justify-end">
              {address?.status !== "accepted" &&
                checkAccess("edit_credential") && (
                  <AddButton
                    title="Accept"
                    onClick={() => {
                      Swal.fire({
                        title: "Are you sure you want to accept this address?",
                        imageUrl: ApprovedSVGModel,
                        imageWidth: 180,
                        imageHeight: 180,
                        showCancelButton: true,
                        confirmButtonColor: "#00AE46",
                        cancelButtonColor: "#d33",
                        confirmButtonText: "Confirm",
                        customClass: {
                          title: "Swal-title-class",
                          popup: "custom-swal-popup",
                        },
                      }).then((result) => {
                        if (result.isConfirmed) {
                          dispatch(
                            ManageAllCredentialAddressAction({
                              id: address?.id,
                              status: "accepted",
                            })
                          );
                        }
                      });
                    }}
                  />
                )}
              {address?.status !== "rejected" &&
                checkAccess("edit_credential") && (
                  <DeleteButton
                    title="Reject"
                    style="ml-2"
                    onClick={() => {
                      Swal.fire({
                        title: "Are you sure you want to reject this address?",
                        imageUrl: RejectSVGModel,
                        imageWidth: 180,
                        imageHeight: 180,
                        showCancelButton: true,
                        confirmButtonColor: "#00AE46",
                        cancelButtonColor: "#d33",
                        confirmButtonText: "Confirm",
                        customClass: {
                          title: "Swal-title-class",
                          popup: "custom-swal-popup",
                        },
                      }).then((result) => {
                        if (result.isConfirmed) {
                          dispatch(
                            ManageAllCredentialAddressAction({
                              id: address?.id,
                              status: "rejected",
                            })
                          );
                        }
                      });
                    }}
                  />
                )}
            </div>
          </div>
        </div>
  );
};

export default PhysicalAddressItem;

import axiosClient from "utils/api";
const URI = "/admin/credential";

//avatar
export const getCredentialAvatars = async (payload) => {
  const URL = `${URI}/avatars/${payload.id}`;

  try {
    const response = await axiosClient().request({
      url: URL,
      method: "get",
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred get all approved avatars:", error);
    throw error;
  }
};

export const manageCredentialAvatars = async (payload) => {
  let { id, ...rest } = payload;
  const URL = `${URI}/avatar/${id}`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "put",
      data: rest,
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred manage approved avatars:", error);
    throw error;
  }
};

//Physical Address
export const getCredentialPhysicalAddress = async (payload) => {
  const URL = `${URI}/address/${payload.id}`;

  try {
    const response = await axiosClient().request({
      url: URL,
      method: "get",
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred get all Physical Address:", error);
    throw error;
  }
};

export const manageCredentialPhysicalAddress = async (payload) => {
  let { id, ...rest } = payload;
  const URL = `${URI}/address/${id}`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "put",
      data: rest,
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred manage approved avatars:", error);
    throw error;
  }
};

//licences
export const getCredentialLicences = async (payload) => {
  const URL = `${URI}/licences/${payload.id}`;

  try {
    const response = await axiosClient().request({
      url: URL,
      method: "get",
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred get all approved licences:", error);
    throw error;
  }
};

export const manageCredentialLicences = async (payload) => {
  let { id, ...rest } = payload;
  const URL = `${URI}/licence/${id}`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "put",
      data: rest,
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred manage approved licences:", error);
    throw error;
  }
};

//socialSecurity
export const getCredentialSocialSecurity = async (payload) => {
  const URL = `${URI}/socialSecurity/${payload.id}`;

  try {
    const response = await axiosClient().request({
      url: URL,
      method: "get",
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred get all approved socialSecurity:", error);
    throw error;
  }
};

export const manageCredentialSocialSecurity = async (payload) => {
  let { id, ...rest } = payload;
  const URL = `${URI}/socialSecurity/${id}`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "put",
      data: rest,
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred manage approved socialSecurity:", error);
    throw error;
  }
};

//certificate
export const getCredentialCertificate = async (payload) => {
  const URL = `${URI}/certificate/${payload.id}`;

  try {
    const response = await axiosClient().request({
      url: URL,
      method: "get",
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred get all approved certificate:", error);
    throw error;
  }
};

export const manageCredentialCertificate = async (payload) => {
  let { id, ...rest } = payload;
  const URL = `${URI}/certificate/${id}`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "put",
      data: rest,
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred manage approved certificate:", error);
    throw error;
  }
};

//serviceLicence
export const getCredentialServiceLicence = async (payload) => {
  const URL = `${URI}/serviceLicence/${payload.id}`;

  try {
    const response = await axiosClient().request({
      url: URL,
      method: "get",
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred get all approved serviceLicence:", error);
    throw error;
  }
};

export const manageCredentialServiceLicence = async (payload) => {
  let { id, ...rest } = payload;
  const URL = `${URI}/serviceLicence/${id}`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "put",
      data: rest,
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred manage approved serviceLicence:", error);
    throw error;
  }
};

// For Get Resume Data For User
export const getProfessionalUserResume = async (payload) => {
  const { id } = payload;
  const URL = `${URI}/resume/${id}`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "get",
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred during Get Resume Data For User:", error);
    throw error;
  }
};

// For Update Resume Status
export const updateResumeStatus = async (payload) => {
  const { id, ...rest } = payload;
  const URL = `${URI}/resume/${id}`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "put",
      data: rest,
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred during Update Resume Staus:", error);
    throw error;
  }
};

// For get all credentials for credentials page
export const getAllCredentials = async (payload) => {
  const URL = `${URI}/allCredentials`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "get",
      params: payload
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred during Get All Credentials:", error);
    throw error;
  }
};

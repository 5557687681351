import axiosClient from "utils/api";
const URI = "/admin/rejectReason";

export const getRejectReasons = async () => {
  const URL = `${URI}/rejectReason`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "get",
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred Get All Reject Reasons:", error);
    throw error;
  }
};

export const deleteRejectReason = async (payload) => {
  const URL = `${URI}/rejectReason/${payload.id}`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "delete",
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred Delete Reject Reason :", error);
    throw error;
  }
};

export const updateRejectReason = async (payload) => {
  let { id, ...rest } = payload;
  const URL = `${URI}/rejectReason/${id}`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "put",
      data: rest,
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred Update Reject Reason :", error);
    throw error;
  }
};

export const addRejectReason = async (payload) => {
  const URL = `${URI}/rejectReason`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "post",
      data: payload,
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred Add Reject Reason :", error);
    throw error;
  }
};

export const updateRejectReasonVisiblity = async (payload) => {
  let { id, ...rest } = payload;
  const URL = `${URI}/visibility/${id}`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "put",
      data: rest,
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred Update Reject Reason Visiblity:", error);
    throw error;
  }
};

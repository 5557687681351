import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  addFAQ,
  deleteFAQ,
  getAllEmail,
  getFAQ,
  getFaqVotes,
  updateFAQ,
  updateVisibilityFAQ,
  getAllChatList,
  updateStatus,
  addChat,
  getMessages,
  addMessage,
  deleteMessage,
  finishChat,
} from "store/services/HelpAndSupport";
import { closeAdminModal } from "store/slices/common/adminModal.slice";
import { openToast } from "store/slices/common/toast.slice";
import { handleAuthError } from "utils/handleAuthError";

// For Fetch All FAQs
export const GetAllFaqsAction = createAsyncThunk(
  "faqs/getAllFaqs",
  async (payload, { rejectWithValue, fulfillWithValue }) => {
    try {
      const res = await getFAQ(payload);
      if (res.status === 1) {
        return fulfillWithValue(res);
      }
      return rejectWithValue(res);
    } catch (error) {
      handleAuthError(error?.response?.status);
      return rejectWithValue(error);
    }
  }
);

// For Fetch All FAQ Votes
export const GetAllFaqsVotesAction = createAsyncThunk(
  "faqs/getAllFaqsVotes",
  async (payload, { rejectWithValue, fulfillWithValue }) => {
    try {
      const res = await getFaqVotes(payload);
      if (res.status === 1) {
        return fulfillWithValue(res.response);
      }
      return rejectWithValue(res);
    } catch (error) {
      handleAuthError(error?.response?.status);
      return rejectWithValue(error);
    }
  }
);

// For Add FAQ
export const AddFaqAction = createAsyncThunk(
  "faqs/addFaqs",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const res = await addFAQ(payload);
      if (res.status === 1) {
        dispatch(closeAdminModal());
        dispatch(openToast({ type: "success", message: res?.message }));
        return fulfillWithValue(res);
      }
      dispatch(openToast({ type: "error", message: res?.message }));
      return rejectWithValue(res);
    } catch (error) {
      dispatch(
        openToast({
          type: "error",
          message: error?.response?.data
            ? error?.response?.data?.message
            : error?.message,
        })
      );
      handleAuthError(error?.response?.status);
      return rejectWithValue(error);
    }
  }
);

// For Update FAQ
export const UpdateFaqAction = createAsyncThunk(
  "faqs/updateFaqs",
  async (payload, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      const res = await updateFAQ(payload);
      if (res.status === 1) {
        dispatch(closeAdminModal());
        dispatch(openToast({ type: "success", message: res?.message }));
        return fulfillWithValue(res);
      }
      dispatch(openToast({ type: "error", message: res?.message }));
      return rejectWithValue(res);
    } catch (error) {
      dispatch(
        openToast({
          type: "error",
          message: error?.response?.data
            ? error?.response?.data?.message
            : error?.message,
        })
      );
      handleAuthError(error?.response?.status);
      return rejectWithValue(error);
    }
  }
);

// For Delete FAQ
export const DeleteFaqAction = createAsyncThunk(
  "faqs/deleteFaqs",
  async (payload, { rejectWithValue, fulfillWithValue, dispatch }) => {
    try {
      const res = await deleteFAQ(payload);
      if (res.status === 1) {
        dispatch(openToast({ type: "success", message: res?.message }));
        dispatch(closeAdminModal());
        return fulfillWithValue(payload);
      }
      dispatch(openToast({ type: "error", message: res?.message }));
      return rejectWithValue(res);
    } catch (error) {
      dispatch(
        openToast({
          type: "error",
          message: error?.response?.data
            ? error?.response?.data?.message
            : error?.message,
        })
      );
      handleAuthError(error?.response?.status);
      return rejectWithValue(error);
    }
  }
);

// For Update Visibility
export const UpdateVisiblityFaqAction = createAsyncThunk(
  "faqs/updateVisiblityFaqAction",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const res = await updateVisibilityFAQ(payload);
      if (res.status === 1) {
        return fulfillWithValue(payload);
      }
      dispatch(openToast({ type: "error", message: res?.message }));
      return rejectWithValue(res);
    } catch (error) {
      dispatch(
        openToast({
          type: "error",
          message: error?.response?.data
            ? error?.response?.data?.message
            : error?.message,
        })
      );
      handleAuthError(error?.response?.status);
      return rejectWithValue(error);
    }
  }
);

// For Fetch Emails
export const GetAllEmailsAction = createAsyncThunk(
  "helpandsupport/emails",
  async (payload, { rejectWithValue, fulfillWithValue }) => {
    try {
      const res = await getAllEmail(payload);
      if (res.status === 1) {
        return fulfillWithValue(res);
      }
      return rejectWithValue(res);
    } catch (error) {
      handleAuthError(error?.response?.status);
      return rejectWithValue(error);
    }
  }
);

// For Fetch Emails
export const UpdateStatusAction = createAsyncThunk(
  "helpandsupport/updateEmailStatus",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const res = await updateStatus(payload);
      if (res.status === 1) {
        dispatch(openToast({ type: "success", message: res?.message }));
        return fulfillWithValue({ ...res, id: payload.id });
      }
      dispatch(openToast({ type: "error", message: res?.message }));
      return rejectWithValue(res);
    } catch (error) {
      dispatch(
        openToast({
          type: "error",
          message: error?.response?.data
            ? error?.response?.data?.message
            : error?.message,
        })
      );
      handleAuthError(error?.response?.status);
      return rejectWithValue(error);
    }
  }
);

// For Fetch h&s chatlist
export const GetAllChatListAction = createAsyncThunk(
  "helpandsupport/chatList",
  async (payload, { rejectWithValue, fulfillWithValue }) => {
    try {
      const res = await getAllChatList();
      if (res.status === 1) {
        return fulfillWithValue(res);
      }
      return rejectWithValue(res);
    } catch (error) {
      handleAuthError(error?.response?.status);
      return rejectWithValue(error);
    }
  }
);

// For Add Chat
export const AddChatAction = createAsyncThunk(
  "helpandsupport/addChat",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const res = await addChat(payload);
      if (res.status === 1) {
        return fulfillWithValue(res);
      }
      return rejectWithValue(res);
    } catch (error) {
      dispatch(
        openToast({
          type: "error",
          message: error?.response?.data
            ? error?.response?.data?.message
            : error?.message,
        })
      );
      handleAuthError(error?.response?.status);
      return rejectWithValue(error);
    }
  }
);

// For Get Single User Messages
export const GetUserMessagesAction = createAsyncThunk(
  "helpandsupport/getMessages",
  async (payload, { rejectWithValue, fulfillWithValue }) => {
    try {
      const res = await getMessages(payload);
      if (res.status === 1) {
        return fulfillWithValue(res);
      }
      return rejectWithValue(res);
    } catch (error) {
      handleAuthError(error?.response?.status);
      return rejectWithValue(error);
    }
  }
);

// For Add Message
export const AddMessageAction = createAsyncThunk(
  "helpandsupport/addMessage",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const res = await addMessage(payload);
      if (res.status === 1) {
        return fulfillWithValue(res);
      }
      return rejectWithValue(res);
    } catch (error) {
      dispatch(
        openToast({
          type: "error",
          message: error?.response?.data
            ? error?.response?.data?.message
            : error?.message,
        })
      );
      handleAuthError(error?.response?.status);
      return rejectWithValue(error);
    }
  }
);

// For Delete Message
export const DeleteMessageAction = createAsyncThunk(
  "helpandsupport/deleteMessage",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const res = await deleteMessage(payload);
      if (res.status === 1) {
        return fulfillWithValue({ ...res, ...payload });
      }
      return rejectWithValue(res);
    } catch (error) {
      dispatch(
        openToast({
          type: "error",
          message: error?.response?.data
            ? error?.response?.data?.message
            : error?.message,
        })
      );
      handleAuthError(error?.response?.status);
      return rejectWithValue(error);
    }
  }
);

// For Finish Message
export const FinishChatAction = createAsyncThunk(
  "helpandsupport/finishChat",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const res = await finishChat(payload);
      if (res.status === 1) {
        return fulfillWithValue(payload);
      }
      return rejectWithValue(res);
    } catch (error) {
      dispatch(
        openToast({
          type: "error",
          message: error?.response?.data
            ? error?.response?.data?.message
            : error?.message,
        })
      );
      handleAuthError(error?.response?.status);
      return rejectWithValue(error);
    }
  }
);

import { Box, IconButton } from "@mui/material";
import { CircleCheck } from "lucide-react";
import React from "react";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import ApprovedSVGModel from "assets/images/ApprovedSVGModel.svg";
import { UpdateStatusAction } from "store/middlewares/HelpAndSupport";
import { TooltipProvider } from "components/atoms";
import checkAccess from "utils/checkAccess";

const EmailActionBar = ({ row }) => {
  const dispatch = useDispatch();

  return (
    <Box className="flex h-full items-center justify-end gap-3">
      {checkAccess("edit_email") && (
        <>
          {row.status.toLowerCase() !== "resolve" && (
            <IconButton
              color="success"
              // sx={{ padding: "0 !important" }}
              disableRipple
              onClick={() => {
                Swal.fire({
                  title:
                    "Are you sure you want to Mark This Query as Resolved?",
                  imageUrl: ApprovedSVGModel,
                  imageWidth: 100,
                  imageHeight: 100,
                  showCancelButton: true,
                  confirmButtonColor: "#3085d6",
                  cancelButtonColor: "#d33",
                  confirmButtonText: "Confirm",
                  customClass: {
                    title: "Swal-title-class",
                    popup: "custom-swal-popup",
                  },
                }).then((result) => {
                  if (result.isConfirmed) {
                    dispatch(
                      UpdateStatusAction({ id: row.id, status: "resolve" })
                    );
                  }
                });
              }}
            >
              <TooltipProvider title="Mark As Resolved">
                <CircleCheck />
              </TooltipProvider>
            </IconButton>
          )}
        </>
      )}
    </Box>
  );
};

export default EmailActionBar;

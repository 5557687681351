import React from "react";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { Input, SelectComponent, SubmitBtn } from "components/atoms";
import {
  Name,
  Features,
  Price,
  Duration,
} from "utils/validation/AddPlans";
import { UpdatePlansAction } from "store/middlewares/plans";

function ModalEditPlans({ row }) {
  const dispatch = useDispatch();

  const { features } = useSelector((state) => state.subscriptionManagement);

  const AddRoleSchema = Yup.object({
    planTitle: Name,
    featuresId: Features,
    price: Price,
    duration: Duration,
  });

  const handleSubmit = (values) => {
    dispatch(
      UpdatePlansAction({
        id: row.id,
        ...values,
        planType: values?.price === 0 ? "free" : "paid",
      })
    );
  };

  const formik = useFormik({
    initialValues: {
      planTitle: row?.planTitle,
      featuresId: row?.PlanFeature?.map((acc) => acc?.feature?.id),
      price: row?.price,
      duration: row?.duration,
    },
    validationSchema: AddRoleSchema,
    enableReinitialize: true,
    onSubmit: handleSubmit,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Input
        inputClassName="rounded-lg border border-solid border-zinc-400 w-full px-2 py-2 mt-2"
        label={"Title"}
        type="text"
        name="planTitle"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.errors.planTitle && formik.touched.planTitle}
        errorMsg={formik.errors.planTitle}
        value={formik.values.planTitle}
        placeholder="Enter Plan Title"
      />
            <Input
        inputClassName="rounded-lg border border-solid border-zinc-400 w-full px-2 py-2 mt-2"
        label={"Price"}
        type="number"
        name="price"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.errors.price && formik.touched.price}
        errorMsg={formik.errors.price}
        value={formik.values.price}
        placeholder="Enter Price"
      />
      <Input
        inputClassName="rounded-lg border border-solid border-zinc-400 w-full px-2 py-2 mt-2"
        label={"Duration (In Days)"}
        type="number"
        name="duration"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.errors.duration && formik.touched.duration}
        errorMsg={formik.errors.duration}
        value={formik.values.duration}
        placeholder="Enter No. of Days"
      />
      <SelectComponent
        className={"px-0"}
        label={"Features"}
        multiSelect={true}
        options={features?.map((country) => {
          return {
            label: country?.name,
            value: country?.id,
          };
        })}
        placeholder="Select Features"
        name={"featuresId"}
        value={formik.values.featuresId}
        error={formik.errors.featuresId && formik.touched.featuresId}
        errorMsg={formik.errors.featuresId}
        onChange={(selectedOption) => {
          formik.setFieldValue(
            "featuresId",
            selectedOption ? selectedOption.map((option) => option.value) : ""
          );
        }}
      />
      <SubmitBtn title={"Update"} />
    </form>
  );
}

export default ModalEditPlans;

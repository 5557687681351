import { createSlice } from "@reduxjs/toolkit";
import {
  AddFacilityAction,
  DeleteFacilityAction,
  GetAllFacilitiesAction,
  UpdateFacilityAction,
  UpdateVisiblityFacilityAction,
} from "store/middlewares/facilities";

// isDeleteMeFulFilled
export const facilitiesSlice = createSlice({
  name: "facilities",
  initialState: {
    facilitiesLoading: false,
    facilities: [],
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(GetAllFacilitiesAction.pending, (state) => {
        state.facilitiesLoading = true;
        state.facilities = [];
      })
      .addCase(GetAllFacilitiesAction.fulfilled, (state, action) => {
        state.facilitiesLoading = false;
        state.facilities = action.payload.response;
      })
      .addCase(GetAllFacilitiesAction.rejected, (state) => {
        state.facilitiesLoading = false;
        state.facilities = [];
      })
      // Delete Job Title
      .addCase(DeleteFacilityAction.fulfilled, (state, action) => {
        let { id } = action.payload;
        let tempArray = state.facilities?.filter((title) => title.id !== id);
        state.facilitiesLoading = false;
        state.facilities = tempArray;
      })
      //Update Job Title
      .addCase(UpdateFacilityAction.fulfilled, (state, action) => {
        let {
          response: { id, ...rest },
        } = action.payload;
        let tempArray = state.facilities?.map((title) => {
          if (title.id === id) {
            return { ...title, ...rest };
          }
          return { ...title };
        });
        state.facilitiesLoading = false;
        state.facilities = tempArray;
      })
      //Add Job Title
      .addCase(AddFacilityAction.fulfilled, (state, action) => {
        state.facilitiesLoading = false;
        state.facilities = [action.payload.response, ...state.facilities];
      })
      //Update Visiblity Job Title
      .addCase(UpdateVisiblityFacilityAction.fulfilled, (state, action) => {
        state.facilitiesLoading = false;
        let { id } = action.payload;
        let tempArray = state.facilities?.map((title) => {
          if (title.id === id) {
            return { ...title, isVisible: !title.isVisible };
          }
          return { ...title };
        });
        state.facilities = tempArray;
      });
  },
});

export default facilitiesSlice.reducer;

import axiosClient from "utils/api";
const URI = "/admin/staticPage";

export const getCms = async (payload) => {
  const URL = `${URI}/staticPage`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "get",
      params: payload,
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred Get cms:", error);
    throw error;
  }
};

export const updateCms = async (payload) => {
  let { id, ...rest } = payload;
  const URL = `${URI}/staticPage/${id}`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "put",
      data: rest,
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred Update cms:", error);
    throw error;
  }
};

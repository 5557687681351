import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  addBanner,
  deleteBanner,
  getBanners,
  updateBanner,
  updateVisiblityBanner,
} from "store/services/banner";
import { closeAdminModal } from "store/slices/common/adminModal.slice";
import { openToast } from "store/slices/common/toast.slice";
import { handleAuthError } from "utils/handleAuthError";
import { addUploadFile } from "store/services/users/professionals";

export const GetAllBannersAction = createAsyncThunk(
  "banners/getAllBanners",
  async (payload, { rejectWithValue, fulfillWithValue }) => {
    try {
      const res = await getBanners();
      if (res.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        return fulfillWithValue(res);
      }
      return rejectWithValue(res);
    } catch (error) {
      handleAuthError(error?.response?.status);
      return rejectWithValue(error);
    }
  }
);

export const DeleteBannerAction = createAsyncThunk(
  "banners/deleteBanner",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const res = await deleteBanner(payload);
      if (res.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        dispatch(openToast({ type: "success", message: res?.message }));
        return fulfillWithValue({ ...res, ...payload });
      }
      dispatch(openToast({ type: "error", message: res?.message }));
      return rejectWithValue({ message: res?.message });
    } catch (error) {
      dispatch(openToast({ type: "error", message: error?.response?.data ? error?.response?.data?.message : error?.message }));
      handleAuthError(error?.response?.status);
      return rejectWithValue(error);
    }
  }
);

export const UpdateBannerAction = createAsyncThunk(
  "banners/updateBanner",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      let { file, oldUrl, ...rest } = payload;
      let url = oldUrl;
      if (file) {
        const uploadFileResponse = await addUploadFile({
          file,
          uploadFor: "banner",
        });
        if (uploadFileResponse.status === 1) {
          url = uploadFileResponse?.response?.urls[0];
        }
      }
      const res = await updateBanner({ bannerUrl: url, ...rest });
      if (res.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        dispatch(closeAdminModal());
        dispatch(openToast({ type: "success", message: res?.message }));
        return fulfillWithValue(res);
      }
      dispatch(openToast({ type: "error", message: res?.message }));
      return rejectWithValue({ message: res?.message });
    } catch (error) {
      dispatch(openToast({ type: "error", message: error?.response?.data ? error?.response?.data?.message : error?.message }));
      handleAuthError(error?.response?.status);
      return rejectWithValue(error);
    }
  }
);

export const AddBannerAction = createAsyncThunk(
  "banners/addBanner",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      let { file,thumbnail, ...rest } = payload;
      const uploadFileResponse = await addUploadFile({
        file,
        uploadFor: "banner",
      });
      const thumbnailFileResponse = await addUploadFile({
        file:thumbnail,
        uploadFor: "banner",
      });

      // console.log(thumbnailFileResponse.status,"dvfjvdvjh");
      console.log(uploadFileResponse.status === 1 , thumbnailFileResponse.status ===1,"statuslog");
      if (uploadFileResponse.status === 1 || uploadFileResponse.status === 1 && thumbnailFileResponse.status === 1 ) {
        console.log("statuslog in");
        let res = undefined
        
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        if(thumbnailFileResponse.status === 1){
           res = await addBanner({
            bannerUrl: uploadFileResponse?.response?.urls[0],
            thumbnail:thumbnailFileResponse?.response?.urls[0],
              ...rest,
            });
        } else {
           res = await addBanner({
            bannerUrl: uploadFileResponse?.response?.urls[0],
            // thumbnail:thumbnailFileResponse?.response?.urls[0],
              ...rest,
            });
        }
          // then full fill data
          // console.log(res, "ressss");
        // const VideoAwsUrl
        
        
        if (res.status === 1) {
          dispatch(closeAdminModal());
          dispatch(openToast({ type: "success", message: res?.message }));
          return fulfillWithValue(res);
        }
        dispatch(openToast({ type: "error", message: res?.message }));
        return rejectWithValue({ message: res?.message });
      }
      dispatch(
        openToast({ type: "error", message: uploadFileResponse?.message })
      );
      return rejectWithValue({ message: uploadFileResponse?.message });
    } catch (error) {
      dispatch(openToast({ type: "error", message: error?.response?.data ? error?.response?.data?.message : error?.message }));
      handleAuthError(error?.response?.status);
      return rejectWithValue(error);
    }
  }
);

//Update Visiblity banner
export const UpdateVisiblityBannerAction = createAsyncThunk(
  "banners/updateVisiblityBanner",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const res = await updateVisiblityBanner(payload);
      if (res.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        return fulfillWithValue({ ...res, ...payload });
      }
      dispatch(openToast({ type: "error", message: res?.message }));
      return rejectWithValue(res);
    } catch (error) {
      dispatch(openToast({ type: "error", message:error?.response?.data ? error?.response?.data?.message : error?.message }));
      handleAuthError(error?.response?.status);
      return rejectWithValue(error);
    }
  }
);

import { IconButton, Typography } from '@mui/material';
import { CustomLoader, TableContainer, TooltipProvider } from 'components/atoms'
import { ChevronsRight } from 'lucide-react';
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { GetJobsAction } from 'store/middlewares/jobs';

const HospitalJobsTab = ({ id }) => {
    const { jobs = [], jobsLoading } = useSelector((state) => state.jobs);
    const navigate = useNavigate()
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(GetJobsAction({ id: id }));
    }, [dispatch]);

    const columns = React.useMemo(
        () => [
            {
                header: "No.",
                cell: ({ row }) => <Typography variant="p">{row.index + 1}</Typography>,
            },
            {
                header: "Shift Title",
                accessorKey: "JobPostDetails.shiftTitle",
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: "Shift Type",
                accessorKey: "JobPostDetails.shiftType",
                enableColumnFilter: false,
                enableSorting: true,
                cell: ({ row }) => (
                    <Typography variant="p">
                        {row?.original?.JobPostDetails?.shiftType === "fullTime" ? "Full Time" : "Part Time"}
                    </Typography>
                )
            },
            {
                header: "Employee Hour Rate",
                accessorKey: "JobPostDetails.employeeHourRate",
                enableColumnFilter: false,
                enableSorting: true,
                cell: ({ row }) => (
                    <Typography variant="p" className="capitalize">
                        {`$ ${row?.original?.JobPostDetails?.employeeHourRate}`}
                    </Typography>
                ),
            },
            {
                header: "Total Cost Per Hour",
                accessorKey: "JobPostDetails.totalCostPerHour",
                enableColumnFilter: false,
                enableSorting: true,
                cell: ({ row }) => (
                    <Typography variant="p" className="capitalize">
                        {`$ ${row?.original?.JobPostDetails?.totalCostPerHour}`}
                    </Typography>
                ),
            },
            {
                header: "Applicant Count",
                accessorKey: "applicantCount",
                enableColumnFilter: false,
                enableSorting: true,
                cell: ({ row }) => (
                    <Typography variant="p">
                        {row?.original?.applicantCount}
                    </Typography>
                ),
            },
            {
                header: 'Action',
                enableColumnFilter: false,
                enableSorting: true,
                cell: () => (
                  <IconButton
                    sx={{ marginLeft: 'auto' }}
                    color="info"
                    disableRipple
                    onClick={() => navigate(`/admin/jobsDetails`)}
                  >
                    <TooltipProvider title="Detail">
                      <ChevronsRight />
                    </TooltipProvider>
                  </IconButton>
                ),
              }
        ],
        []
    );
    return (
        <div className="Hospital-User-page h-full w-full">
            {!jobsLoading ? (
                <div className="card mt-5">
                    <div className="card-body p-3">
                        <TableContainer
                            isPagination={true}
                            isSelect={true}
                            isGlobalFilter={true}
                            columns={columns}
                            data={jobs}
                            customPageSize={10}
                            divclassName="my-2 col-span-12 overflow-x-auto lg:col-span-12"
                            tableclassName="hover group dataTable w-full text-sm align-middle whitespace-nowrap no-footer"
                            theadclassName="border-b border-slate-200 "
                            trclassName="group-[.stripe]:even:bg-slate-50 group-[.stripe]:dark:even:bg-zink-600 transition-all duration-150 ease-linear group-[.hover]:hover:bg-slate-50 [&.selected]:bg-custom-500  [&.selected]:text-custom-50 "
                            thclassName="p-3 group-[.bordered]:border group-[.bordered]:border-slate-200 sorting px-3 py-4 text-slate-900 bg-slate-200/50 font-semibold text-left"
                            tdclassName="p-3 group-[.bordered]:border group-[.bordered]:border-slate-200 max-w-[200px] overflow-hidden text-pretty overflow-break-word"
                            PaginationClassName="flex flex-col items-center mt-5 md:flex-row"
                        />
                    </div>
                </div>
            ) : (
                <CustomLoader />
            )}
        </div>
    )
}

export default HospitalJobsTab
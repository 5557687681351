import axiosClient from "utils/api";

export const getMe = async () => {
  try {
    const response = await axiosClient().request({
      url: "/admin/auth/getProfile",
      method: "get",
    });
    return response.data;
  } catch (error) {
    console.error("Error at Get ME Service", error);
    throw error;
  }
};

export const updateMe = async (payload) => {
  let { id, ...rest} = payload
  try {
    const response = await axiosClient().request({
      url: `/admin/auth/updateProfile/${id}`,
      method: "put",
      data: rest,
    });
    return response.data;
  } catch (error) {
    console.error("Error at Update Account Service", error);
    throw error;
  }
};

export const changePassword = async (payload) => {
  try {
    const response = await axiosClient().request({
      url: "/admin/auth/changePassword",
      method: "post",
      data: payload,
    });
    return response.data;
  } catch (error) {
    console.error("Error at Change Password Service", error);
    throw error;
  }
};

import { createSlice } from "@reduxjs/toolkit";
import {
  AddAccessAction,
  DeleteAccessAction,
  GetAllAccessAction,
  UpdateAccessAction,
} from "store/middlewares/access";

export const accessSlice = createSlice({
  name: "access",
  initialState: {
    accessLoading: false,
    access: [],
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(GetAllAccessAction.pending, (state) => {
        state.accessLoading = true;
        state.access = [];
      })
      .addCase(GetAllAccessAction.fulfilled, (state, action) => {
        state.accessLoading = false;
        state.access = action.payload.response.data
          ? action.payload.response.data
          : action.payload.response;
      })
      .addCase(GetAllAccessAction.rejected, (state) => {
        state.accessLoading = false;
        state.access = [];
      })
      // Delete Job Title
      .addCase(DeleteAccessAction.fulfilled, (state, action) => {
        let { id } = action.payload;
        let tempArray = state.access?.filter((title) => title.id !== id);
        state.accessLoading = false;
        state.access = tempArray;
      })
      //Update Job Title
      .addCase(UpdateAccessAction.fulfilled, (state, action) => {
        let obj = action.payload.response.updateRecord
        ? action.payload.response.updateRecord
        : action.payload.response;
        let { id, ...rest } = obj;
        let tempArray = state.access?.map((title) => {
          if (title.id === id) {
            return { ...title, ...rest };
          }
          return { ...title };
        });
        state.accessLoading = false;
        state.access = tempArray;
      })
      //Add city
      .addCase(AddAccessAction.fulfilled, (state, action) => {
        state.accessLoading = false;
        let Obj = action.payload.response.newcode
          ? action.payload.response.newcode
          : action.payload.response;
        state.access = [...state.access, Obj];
      });
  },
});

export default accessSlice.reducer;

import React from "react";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import { Input, SubmitBtn } from "components/atoms";
import { UpdateRejectReasonAction } from "store/middlewares/RejectReason";
import { Reason } from "utils/validation/AddDeleteReason";

function ModalEditReject({ row }) {
  const dispatch = useDispatch();

  const AddrejectSchema = Yup.object({ reason: Reason });
  const handleSubmit = (values) => {
    dispatch(UpdateRejectReasonAction({ id: row?.id, ...values }));
  };

  const formik = useFormik({
    initialValues: { reason: row?.reason, type: row?.type },
    validationSchema: AddrejectSchema,
    enableReinitialize: true,
    onSubmit: handleSubmit,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Input
        inputClassName="rounded-lg border border-solid border-zinc-400 w-full px-2 py-2 mt-2"
        label={"Reason"}
        error={formik.errors.reason && formik.touched.reason}
        errorMsg={formik.errors.reason}
        value={formik.values.reason}
        type="text"
        name="reason"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        placeholder="Enter Reject Reason"
      />
      <SubmitBtn title={"Update"} />
    </form>
  );
}

export default ModalEditReject;

import axiosClient from "utils/api";
const URI = "/admin/auth";

export const loginAdmin = async (payload) => {
  const URL = `${URI}/login`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "post",
      data: payload,
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred during login:", error);
    throw error;
  }
};

export const logOutAdmin = async () => {
  const URL = `${URI}/logout`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "delete",
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred during Logout:", error);
    throw error;
  }
};

export const changePassword = async (payload) => {
  const URL = `${URI}/changePassword`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "post",
      data: payload,
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred during Change Password:", error);
    throw error;
  }
};

export const forgotPassword = async (payload) => {
  const URL = `${URI}/forgotPassword`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "post",
      data: payload,
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred during Change Password:", error);
    throw error;
  }
};

export const resetPassword = async (payload) => {
  let { id, ...rest } = payload;
  const URL = `${URI}/resetPassword/${id}`;
  try {
    const response = await axiosClient().request({
      url: URL,
      method: "put",
      data: rest,
    });
    return response.data;
  } catch (error) {
    console.error("Error occurred during Change Password:", error);
    throw error;
  }
};

export const validateToken = async (payload) => {
  try {
    const response = await axiosClient().request({
      url: `${URI}/validateToken`,
      method: "post",
      data: payload,
    });
    return response.data;
  } catch (err) {
    return err;
  }
};

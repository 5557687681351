import React from "react";
import checkAccess from "utils/checkAccess";

function JobTitle() {
  return (
    <React.Fragment>
      <div className="card">
        <div className="card-body">
          <h6 className="mb-4 text-16 font-bold underline">Job Title :</h6>
          <div className="grid grid-cols-1 gap-4">
            <div>
              <h6 className="text-base capitalize font-bold text-center">
                lorem ipsam
              </h6>
            </div>
          </div>
        </div>
      </div>

      <div className="card-body">
        {checkAccess("edit_credential") && (
          <div className="grid grid-cols-1 gap-4 md:grid-cols-6 mx-3 ">
            <button
              type="button"
              className="col-start-5 text-white capitalize btn px-3 bg-[#0ca7dfd3] hover:text-white hover:bg-[#0CA8DF] p-2 rounded-lg"
            >
              Accepted
            </button>
            <button
              type="button"
              className="col-start-6 text-white capitalize btn px-3 bg-[#FF5C5C] hover:text-white hover:bg-[#ff3030] p-2 rounded-lg"
            >
              Rejected
            </button>
          </div>
        )}
      </div>
    </React.Fragment>
  );
}

export default JobTitle;

import { createAsyncThunk } from "@reduxjs/toolkit";
import {addBadges,deleteBadges,getBadges,updateBadges
} from "store/services/badges";
import { closeAdminModal } from "store/slices/common/adminModal.slice";
import { openToast } from "store/slices/common/toast.slice";
import { handleAuthError } from "utils/handleAuthError";
import { addUploadFile } from "store/services/users/professionals";

export const GetAllBadgesAction = createAsyncThunk(
  "badges/getAllBadges",
  async (payload, { rejectWithValue, fulfillWithValue }) => {
    try {
      const res = await getBadges();
      if (res.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        return fulfillWithValue(res);
      }
      return rejectWithValue(res);
    } catch (error) {
      handleAuthError(error?.response?.status);
      return rejectWithValue(error);
    }
  }
);

export const DeleteBadgesAction = createAsyncThunk(
  "badges/deleteBadges",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const res = await deleteBadges(payload);
      if (res.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        dispatch(openToast({ type: "success", message: res?.message }));
        return fulfillWithValue({ ...res, ...payload });
      }
      dispatch(openToast({ type: "error", message: res?.message }));
      return rejectWithValue({ message: res?.message });
    } catch (error) {
      dispatch(openToast({ type: "error", message: error?.response?.data ? error?.response?.data?.message : error?.message }));
      handleAuthError(error?.response?.status);
      return rejectWithValue(error);
    }
  }
);

export const UpdateBadgesAction = createAsyncThunk(
  "badges/updateBadges",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      let { file, oldUrl, ...rest } = payload;
      let url = oldUrl;
      if (file) {
        const uploadFileResponse = await addUploadFile({
          file,
          // uploadFor: "banner",
        });
        if (uploadFileResponse.status === 1) {
          url = uploadFileResponse?.response?.urls[0];
        }
      }
      const res = await updateBadges({ bannerUrl: url, ...rest });
      if (res.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        dispatch(closeAdminModal());
        dispatch(openToast({ type: "success", message: res?.message }));
        return fulfillWithValue(res);
      }
      dispatch(openToast({ type: "error", message: res?.message }));
      return rejectWithValue({ message: res?.message });
    } catch (error) {
      dispatch(openToast({ type: "error", message: error?.response?.data ? error?.response?.data?.message : error?.message }));
      handleAuthError(error?.response?.status);
      return rejectWithValue(error);
    }
  }
);

export const AddBadgesAction = createAsyncThunk(
  "badges/addBadges",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      let { file, ...rest } = payload;
      const uploadFileResponse = await addUploadFile({
        file,
        uploadFor: "badges",
      });
      if (uploadFileResponse.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        const res = await addBadges({
          image: uploadFileResponse?.response?.urls[0],
          ...rest,
        });
        if (res.status === 1) {
          dispatch(closeAdminModal());
          dispatch(openToast({ type: "success", message: res?.message }));
          return fulfillWithValue(res);
        }
        dispatch(openToast({ type: "error", message: res?.message }));
        return rejectWithValue({ message: res?.message });
      }
      dispatch(
        openToast({ type: "error", message: uploadFileResponse?.message })
      );
      return rejectWithValue({ message: uploadFileResponse?.message });
    } catch (error) {
      dispatch(openToast({ type: "error", message: error?.response?.data ? error?.response?.data?.message : error?.message }));
      handleAuthError(error?.response?.status);
      return rejectWithValue(error);
    }
  }
);


import React, { lazy } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import useDocumentTitle from "hooks/useDocumentTitle";

//Pages
const Login = lazy(() => import("pages/Login/Login"));
const ForgetPassword = lazy(() => import("pages/ForgetPassword"));
const ResetPassSuccessfully = lazy(() =>
  import("pages/ResetPassSuccessfully")
);
const ResetPasswordAdmin = lazy(() => import("pages/ResetPasswordAdmin"));

const basePath = "";

const generatePath = (slug) => {
  return slug ? `${basePath}/${slug}` : `${basePath}`;
};

const webRoutes = [
  {
    path: generatePath("forget-password"),
    element: <ForgetPassword />,
  },
  {
    path: generatePath(),
    element: <Login />,
  },
  {
    path: generatePath("resetpassword/successfully"),
    element: <ResetPassSuccessfully />,
  },
  {
    path: generatePath("resetAdminPassword/:id"),
    element: <ResetPasswordAdmin />,
  },
];
const WebNavigation = () => {
  useDocumentTitle("Vital - Admin");

  const user = JSON.parse(localStorage.getItem("aboutcare-admin"));

  return (
    <Routes>
      <Route
        path=""
        element={!user ? <Login /> : <Navigate to="/admin/dashboard" />}
      />
      {webRoutes.map((route, index) => {
        return (
          <Route
            key={`${route.path}${index}`}
            path={route.path}
            element={route.element}
          />
        );
      })}
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};

export default WebNavigation;

import { createSlice } from "@reduxjs/toolkit";
import {
  AddJobTitleAction,
  DeleteJobTitleAction,
  GetAllJobTitleAction,
  UpdateJobTitleAction,
  UpdateVisiblityJobTitleAction,
} from "store/middlewares/jobTitle";

// isDeleteMeFulFilled
export const jobTitleSlice = createSlice({
  name: "jobTitle",
  initialState: {
    jobTitlesLoading: false,
    jobTitles: [],
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(GetAllJobTitleAction.pending, (state) => {
        state.jobTitlesLoading = true;
        state.jobTitles = [];
      })
      .addCase(GetAllJobTitleAction.fulfilled, (state, action) => {
        state.jobTitlesLoading = false;
        state.jobTitles = action.payload.response;
      })
      .addCase(GetAllJobTitleAction.rejected, (state) => {
        state.jobTitlesLoading = false;
        state.jobTitles = [];
      })
      // Delete Job Title
      .addCase(DeleteJobTitleAction.fulfilled, (state, action) => {
        let { id } = action.payload;
        let tempArray = state.jobTitles?.filter((title) => title.id !== id);
        state.jobTitlesLoading = false;
        state.jobTitles = tempArray;
      })
      //Update Job Title
      .addCase(UpdateJobTitleAction.fulfilled, (state, action) => {
        let { response: { id, ...rest } } = action.payload;
        let tempArray = state.jobTitles?.map((title) => {
          if (title.id === id) {
            return { ...title, ...rest };
          }
          return { ...title };
        });
        state.jobTitlesLoading = false;
        state.jobTitles = tempArray;
      })
      //Add Job Title
      .addCase(AddJobTitleAction.fulfilled, (state, action) => {
        state.jobTitlesLoading = false;
        state.jobTitles = [...state.jobTitles, action.payload.response];
      })
      //Update Visiblity Job Title
      .addCase(UpdateVisiblityJobTitleAction.fulfilled, (state, action) => {
        state.jobTitlesLoading = false;
        let { id } = action.payload;
        let tempArray = state.jobTitles?.map((title) => {
          if (title.id === id) {
            return { ...title, isVisible: !title.isVisible };
          }
          return { ...title };
        });
        state.jobTitles = tempArray;
      });
  },
});

export default jobTitleSlice.reducer;

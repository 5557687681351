import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getCredentialAvatars,
  getCredentialLicences,
  getCredentialCertificate,
  getCredentialServiceLicence,
  getCredentialSocialSecurity,
  manageCredentialAvatars,
  manageCredentialLicences,
  manageCredentialCertificate,
  manageCredentialServiceLicence,
  manageCredentialSocialSecurity,
  getProfessionalUserResume,
  updateResumeStatus,
  getCredentialPhysicalAddress,
  manageCredentialPhysicalAddress,
  getAllCredentials,
} from "store/services/credential";
import { openToast } from "store/slices/common/toast.slice";
import { handleAuthError } from "utils/handleAuthError";

//Get All  avatar
export const GetAllCredentialAvatarAction = createAsyncThunk(
  "avatar/getAllCredentialAvatar",
  async (payload, { rejectWithValue, fulfillWithValue }) => {
    try {
      const res = await getCredentialAvatars(payload);
      if (res.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data

        return fulfillWithValue(res);
      }
      return rejectWithValue(res);
    } catch (error) {
      handleAuthError(error?.response?.status);
      return rejectWithValue(error);
    }
  }
);

//manage All avatar
export const ManageAllCredentialAvatarAction = createAsyncThunk(
  "avatar/manageAllCredentialAvatar",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const res = await manageCredentialAvatars(payload);
      if (res.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        dispatch(openToast({ type: "success", message: res?.message }));
        return fulfillWithValue(res);
      }

      dispatch(openToast({ type: "error", message: res?.message }));
      return rejectWithValue(res);
    } catch (error) {
      dispatch(openToast({ type: "error", message: error?.response?.data ? error?.response?.data?.message : error?.message }));
      handleAuthError(error?.response?.status);
      return rejectWithValue(error);
    }
  }
);

//Get All Licences
export const GetAllCredentialLicencesAction = createAsyncThunk(
  "licences/getAllCredentialLicences",
  async (payload, { rejectWithValue, fulfillWithValue }) => {
    try {
      const res = await getCredentialLicences(payload);
      if (res.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        return fulfillWithValue(res);
      }
      return rejectWithValue(res);
    } catch (error) {
      handleAuthError(error?.response?.status);
      return rejectWithValue(error);
    }
  }
);

//manage All Licences
export const ManageAllCredentialLicencesAction = createAsyncThunk(
  "licences/manageAllCredentialLicences",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const res = await manageCredentialLicences(payload);
      if (res.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        dispatch(openToast({ type: "success", message: res?.message }));
        return fulfillWithValue(res);
      }
      dispatch(openToast({ type: "error", message: res?.message }));
      return rejectWithValue(res);
    } catch (error) {
      dispatch(openToast({ type: "error", message: error?.response?.data ? error?.response?.data?.message : error?.message }));
      handleAuthError(error?.response?.status);
      return rejectWithValue(error);
    }
  }
);

//Get All socialSecurity
export const GetAllCredentialSocialSecurityAction = createAsyncThunk(
  "socialSecurity/getAllCredentialSocialSecurity",
  async (payload, { rejectWithValue, fulfillWithValue }) => {
    try {
      const res = await getCredentialSocialSecurity(payload);
      if (res.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        return fulfillWithValue(res);
      }
      return rejectWithValue(res);
    } catch (error) {
      handleAuthError(error?.response?.status);
      return rejectWithValue(error);
    }
  }
);

//manage All SocialSecurity
export const ManageAllCredentialSocialSecurityAction = createAsyncThunk(
  "socialSecurity/manageAllCredentialSocialSecurity",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const res = await manageCredentialSocialSecurity(payload);
      if (res.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        dispatch(openToast({ type: "success", message: res?.message }));
        return fulfillWithValue(res);
      }
      dispatch(openToast({ type: "error", message: res?.message }));
      return rejectWithValue(res);
    } catch (error) {
      dispatch(openToast({ type: "error", message: error?.response?.data ? error?.response?.data?.message : error?.message }));
      handleAuthError(error?.response?.status);
      return rejectWithValue(error);
    }
  }
);

//Get All Certificate
export const GetAllCredentialCertificateAction = createAsyncThunk(
  "certificate/getAllCredentialCertificate",
  async (payload, { rejectWithValue, fulfillWithValue }) => {
    try {
      const res = await getCredentialCertificate(payload);
      if (res.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        return fulfillWithValue(res);
      }
      return rejectWithValue(res);
    } catch (error) {
      handleAuthError(error?.response?.status);
      return rejectWithValue(error);
    }
  }
);

//manage All Certificate
export const ManageAllCredentialCertificateAction = createAsyncThunk(
  "certificate/manageAllCredentialCertificate",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const res = await manageCredentialCertificate(payload);
      if (res.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        dispatch(openToast({ type: "success", message: res?.message }));
        return fulfillWithValue(res);
      }
      dispatch(openToast({ type: "error", message: res?.message }));
      return rejectWithValue(res);
    } catch (error) {
      dispatch(openToast({ type: "error", message: error?.response?.data ? error?.response?.data?.message : error?.message }));
      handleAuthError(error?.response?.status);
      return rejectWithValue(error);
    }
  }
);

//Get All ServiceLicence
export const GetAllCredentialServiceLicenceAction = createAsyncThunk(
  "serviceLicence/getAllCredentialServiceLicence",
  async (payload, { rejectWithValue, fulfillWithValue }) => {
    try {
      const res = await getCredentialServiceLicence(payload);
      if (res.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        return fulfillWithValue(res);
      }
      return rejectWithValue(res);
    } catch (error) {
      handleAuthError(error?.response?.status);
      return rejectWithValue(error);
    }
  }
);

//manage All ServiceLicence
export const ManageAllCredentialServiceLicenceAction = createAsyncThunk(
  "serviceLicence/manageAllCredentialServiceLicence",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const res = await manageCredentialServiceLicence(payload);
      if (res.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        dispatch(openToast({ type: "success", message: res?.message }));
        return fulfillWithValue(res);
      }
      dispatch(openToast({ type: "error", message: res?.message }));
      return rejectWithValue(res);
    } catch (error) {
      dispatch(openToast({ type: "error", message: error?.response?.data ? error?.response?.data?.message : error?.message }));
      handleAuthError(error?.response?.status);
      return rejectWithValue(error);
    }
  }
);

// For Get Resume Data For User
export const GetProfessionalUserResume = createAsyncThunk(
  "professionals/getResume",
  async (payload, { rejectWithValue, fulfillWithValue }) => {
    try {
      const res = await getProfessionalUserResume(payload);
      if (res.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        return fulfillWithValue(res);
      }
      return rejectWithValue(res);
    } catch (error) {
      handleAuthError(error?.response?.status);
      return rejectWithValue(error);
    }
  }
);
// For Update Resume Data For User
export const UpdateProfessionalUserResume = createAsyncThunk(
  "professionals/updateResumeStatus",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const response = await updateResumeStatus(payload);
      if (response.status === 1) {
        dispatch(openToast({ type: "success", message: response?.message }));
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        return fulfillWithValue({ ...response, ...payload });
      }
      dispatch(openToast({ type: "error", message: response?.message }));
      return rejectWithValue({ message: response?.message });
    } catch (error) {
      dispatch(openToast({ type: "error", message: error?.response?.data ? error?.response?.data?.message : error?.message }));
      handleAuthError(error?.response?.status);
      return rejectWithValue(error);
    }
  }
);

//Get All Physical Address
export const GetAllCredentialAddressAction = createAsyncThunk(
  "avatar/getAllCredentialAddress",
  async (payload, { rejectWithValue, fulfillWithValue }) => {
    try {
      const res = await getCredentialPhysicalAddress(payload);
      if (res.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data

        return fulfillWithValue(res);
      }
      return rejectWithValue(res);
    } catch (error) {
      handleAuthError(error?.response?.status);
      return rejectWithValue(error);
    }
  }
);

//manage All Physical Address
export const ManageAllCredentialAddressAction = createAsyncThunk(
  "avatar/manageAllCredentialAddress",
  async (payload, { rejectWithValue, dispatch, fulfillWithValue }) => {
    try {
      const res = await manageCredentialPhysicalAddress(payload);
      if (res.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        dispatch(openToast({ type: "success", message: res?.message }));
        return fulfillWithValue(res);
      }

      dispatch(openToast({ type: "error", message: res?.message }));
      return rejectWithValue(res);
    } catch (error) {
      dispatch(openToast({ type: "error", message: error?.response?.data ? error?.response?.data?.message : error?.message }));
      handleAuthError(error?.response?.status);
      return rejectWithValue(error);
    }
  }
);

// Get All Credentials For Credentials Page
export const GetAllCredentialsAction = createAsyncThunk(
  "credentials/allCredentials",
  async (payload, { rejectWithValue, fulfillWithValue }) => {
    try {
      const res = await getAllCredentials(payload);
      if (res.status === 1) {
        // if any async operation need to be done on response data, do it here,
        // then full fill data
        return fulfillWithValue(res);
      }
      return rejectWithValue(res);
    } catch (error) {
      handleAuthError(error?.response?.status);
      return rejectWithValue(error);
    }
  }
)

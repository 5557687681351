function loginUser({ user }) {
    localStorage.setItem(
        'aboutcare-admin',
        JSON.stringify(user)
    );
}

function removeFromLocalStorage(name) {
    localStorage.removeItem(`${name}`);
}

function loginWeb({ user }) {
    localStorage.setItem(
        'user-details',
        JSON.stringify(user)
    );
}


const LocalstorageService = {
    loginUser,
    loginWeb,
    removeFromLocalStorage
};

export default LocalstorageService;
import { createSlice } from "@reduxjs/toolkit";
import {
  GetAllRejectReasonsAction,
  AddRejectReasonAction,
  DeleteRejectReasonAction,
  UpdateRejectReasonAction,
  UpdateVisiblityRejectReasonAction,
} from "store/middlewares/RejectReason";

// isDeleteMeFulFilled
export const rejectReasonsSlice = createSlice({
  name: "rejectReason",
  initialState: {
    rejectReasonsLoading: false,
    rejectReasons: [],
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(GetAllRejectReasonsAction.pending, (state) => {
        state.rejectReasonsLoading = true;
        state.rejectReasons = [];
      })
      .addCase(GetAllRejectReasonsAction.fulfilled, (state, action) => {
        state.rejectReasonsLoading = false;
        state.rejectReasons = action.payload.response;
      })
      .addCase(GetAllRejectReasonsAction.rejected, (state) => {
        state.rejectReasonsLoading = false;
        state.rejectReasons = [];
      })
      // Delete  Delete Reason
      .addCase(DeleteRejectReasonAction.fulfilled, (state, action) => {
        let { id } = action.payload;
        let tempArray = state.rejectReasons?.filter((title) => title.id !== id);
        state.rejectReasonsLoading = false;
        state.rejectReasons = tempArray;
      })
      //Update  Delete Reason
      .addCase(UpdateRejectReasonAction.fulfilled, (state, action) => {
        let {
          response: { id, ...rest },
        } = action.payload;
        let tempArray = state.rejectReasons?.map((title) => {
          if (title.id === id) {
            return { ...title, ...rest };
          }
          return { ...title };
        });
        state.rejectReasonsLoading = false;
        state.rejectReasons = tempArray;
      })
      //Add  Delete Reason
      .addCase(AddRejectReasonAction.fulfilled, (state, action) => {
        state.rejectReasonsLoading = false;
        state.rejectReasons = [...state.rejectReasons, action.payload.response];
      })

      //Update Visiblity Delete Reason
      .addCase(UpdateVisiblityRejectReasonAction.fulfilled, (state, action) => {
        state.rejectReasonsLoading = false;
        let { id } = action.payload;
        let tempArray = state.rejectReasons?.map((Reasons) => {
          if (Reasons.id === id) {
            return { ...Reasons, isVisible: !Reasons.isVisible };
          }
          return { ...Reasons };
        });
        state.rejectReasons = tempArray;
      });
  },
});

export default rejectReasonsSlice.reducer;

import { Box, IconButton } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import {
  ArchiveRestore,
  ChevronsRight,
  ShieldBan,
  ShieldCheck,
  UserRoundCheck,
} from "lucide-react";
import { useDispatch } from "react-redux";
import { openAdminModal } from "store/slices/common/adminModal.slice";
import { ModalBlockHospital, ModalDeleteHospital } from "components/molecules";
import ApprovedSVGModel from "assets/images/ApprovedSVGModel.svg";
import EditIcon from "assets/images/editpenpapercolor.svg";
import DeleteIcon from "assets/images/deleteIconSVGcolor.svg";
import {
  ActiveHospitalAction,
  RestoreHospitalAction,
  UnblockHospitalAction,
} from "store/middlewares/users/hospitals";
import { TooltipProvider } from "components/atoms";
import checkAccess from "utils/checkAccess";
import Swal from "sweetalert2";

const ActiveHospitalUser = ({ row, type }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <Box className="flex h-full items-center justify-end">
      {/* For active user */}
      {type === "pending" && checkAccess("edit_hospital") && (
        <IconButton
          color="info"
          disableRipple
          onClick={() => {
            Swal.fire({
              title: "Are you sure you want to Activate this User?",
              imageUrl: ApprovedSVGModel,
              imageWidth: 100,
              imageHeight: 100,
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Confirm",
              customClass: {
                title: "Swal-title-class",
                popup: "custom-swal-popup",
              },
            }).then((result) => {
              if (result.isConfirmed) {
                dispatch(ActiveHospitalAction({ id: row.id }));
              }
            });
          }}
        >
          <TooltipProvider title="Active hospital">
            <UserRoundCheck />
          </TooltipProvider>
        </IconButton>
      )}

      {/* For block user */}
      {type === "active" && checkAccess("edit_hospital") && (
        <IconButton
          color="warning"
          disableRipple
          onClick={() => {
            dispatch(
              openAdminModal({
                title: `Block ${row?.name}`,
                content: <ModalBlockHospital row={row} />,
              })
            );
          }}
        >
          <TooltipProvider title="Block">
            <ShieldBan />
          </TooltipProvider>
        </IconButton>
      )}

      {/* For unblock user */}
      {type === "inactive" && checkAccess("edit_hospital") && (
        <IconButton
          color="success"
          disableRipple
          onClick={() => {
            Swal.fire({
              title: "Are you sure you want to Unblock this User?",
              imageUrl: ApprovedSVGModel,
              imageWidth: 100,
              imageHeight: 100,
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Confirm",
              customClass: {
                title: "Swal-title-class",
                popup: "custom-swal-popup",
              },
            }).then((result) => {
              if (result.isConfirmed) {
                dispatch(UnblockHospitalAction({ id: row.id }));
              }
            });
          }}
        >
          <TooltipProvider title="Unblock">
            <ShieldCheck />
          </TooltipProvider>
        </IconButton>
      )}

      {/* For restore user */}
      {type === "deleted" && checkAccess("delete_hospital") && (
        <IconButton
          color="warning"
          disableRipple
          onClick={() => {
            Swal.fire({
              title: "Are you sure you want to Restore this User?",
              imageUrl: ApprovedSVGModel,
              imageWidth: 100,
              imageHeight: 100,
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Confirm",
              customClass: {
                title: "Swal-title-class",
                popup: "custom-swal-popup",
              },
            }).then((result) => {
              if (result.isConfirmed) {
                dispatch(RestoreHospitalAction({ id: row.id }));
              }
            });
          }}
        >
          <TooltipProvider title="Restore">
            <ArchiveRestore />
          </TooltipProvider>
        </IconButton>
      )}

      {/* For Navigate to details */}
      {type !== "deleted" && checkAccess("read_hospital") && (
        <>
        <IconButton onClick={() => navigate(`/admin/hospital/edit/${row?.id}`)}>
          <TooltipProvider title="Edit">
            <img src={EditIcon} className="inline-block size-5 mr-1" />
          </TooltipProvider>
        </IconButton>

        <IconButton
          color="warning"
          disableRipple
          onClick={() => {
            dispatch(
              openAdminModal({
                title: `Delete ${row?.name} `,
                content: <ModalDeleteHospital row={row} />,
              })
            );
          }}
        >
          <TooltipProvider title="Delete">
          <img src={DeleteIcon} className="inline-block size-5 mr-1" />
          </TooltipProvider>
        </IconButton>

        <IconButton
          color="info"
          disableRipple
          onClick={() => navigate(`/admin/hospital/${type}/${row?.id}`)}
        >
          <TooltipProvider title="Detail">
            <ChevronsRight />
          </TooltipProvider>
        </IconButton>
        </>
      )}
    </Box>
  );
};

export default ActiveHospitalUser;

import React from "react";
import { Link } from "react-router-dom";
import BlankImg from "../../../assets/images/blank.png";

function ChatMessageItems({ isSender, msg, img }) {
  return (
    <li
      className={`flex chat-message group/item ${isSender && "justify-end"}`}
    //   key={itemIndex}
    >
      <div className={`flex gap-3 ${isSender ? "flex-row-reverse" : ""}`}>
        <Link to="#!" className="size-10">
          <img src={isSender ? BlankImg : img} alt="profile img" className="w-full h-full rounded-full object-cover" />
        </Link>
        <div className="grow group-[.right]/item:order-2 flex flex-col gap-3">
          <div className={`flex gap-3 ${isSender ? "flex-row-reverse" : ""}`}>
            <div
              className={`p-2 ${isSender ? "bg-[#0ca7dfc2] text-white" : "bg-white"
                } rounded-md relative shadow-sm max-w-[400px] break-words`}
            >
              {msg}
              {/* {item.attachments.map((attachment, index) => (
                <div key={index}>
                  {attachment.type === "audio" && (
                    <audio
                      controls
                      src={attachment.file}
                      className="w-full mt-2"
                    />
                  )}
                  {attachment.type === "document" && (
                    <a
                      href={attachment.file}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="block mt-2 text-blue-600"
                    >
                      Download Document
                    </a>
                  )}
                </div>
              ))} */}
            </div>
          </div>
        </div>
      </div>
    </li>
  );
}

export default ChatMessageItems;

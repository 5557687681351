import React from "react";

import { NavLink } from "react-router-dom";

import { menuData } from "utils/LayoutMenuData";
import { Dropdown, withRouter } from "components/atoms";
import checkAccess from "utils/checkAccess";

//i18n
// import { withTranslation } from "react-i18next";

const VerticalLayout = () => {
  return (
    <React.Fragment>
      {(menuData || [])
        ?.filter((item) => checkAccess(`${item?.action}_${item?.id}`))
        .map((item, key) => {
          return (
            <React.Fragment key={key}>
              {item["isTitle"] ? (
                <li
                  className="px-4 py-1 uppercase font-medium text-[11px] cursor-default tracking-wider 
                group-data-[sidebar-size=sm]:hidden inline-block group-data-[sidebar-size=md]:block group-data-[sidebar-size=md]:underline 
                group-data-[sidebar-size=md]:text-center text-zink-200"
                >
                  <span data-key="t-menu">{item.label}</span>
                </li>
              ) : item.subItems ? (
                <Dropdown
                  className="relative group/sm"
                  active={window.location.pathname}
                  id={item.id}
                >
                  <Dropdown.Trigger
                    type="a"
                    className="relative dropdown-button flex items-center pl-3 pr-5 mx-3 my-1 group/menu-link 
                    text-vertical-menu-item-font-size 
                    font-normal transition-all duration-75 ease-linear rounded-md py-2.5 
                    text-zink-200 
                    hover:text-custom-500 
                    hover:bg-zink-600 
                    [&.active]:bg-zink-600 
                    [&.active]:text-vertical-menu-item-active-dark 
                    group-data-[sidebar-size=md]:block 
                    group-data-[sidebar-size=md]:text-center 
                    group-data-[sidebar-size=sm]:group-hover/sm:w-[calc(theme('spacing.vertical-menu-sm')_*_3.63)] 
                    group-data-[sidebar-size=sm]:group-hover/sm:bg-vertical-menu 
                    group-data-[sidebar-size=sm]:group-hover/sm:bg-vertical-menu-dark 
                    group-data-[layout=horizontal]:m-0 
                    group-data-[layout=horizontal]:pr-8 
                    group-data-[layout=horizontal]:hover:bg-transparent  
                    group-data-[layout=horizontal]:[&.active]:bg-transparent 
                    group-data-[layout=horizontal]:text-zink-200 
                    group-data-[layout=horizontal]:[&.active]:text-custom-500  
                    group-data-[layout=horizontal]:[&.dropdown-button]:before:rotate-90 
                    group-data-[layout=horizontal]:[&.dropdown-button]:[&.show]:before:rotate-0 
                    group-data-[sidebar-size=sm]:my-0 
                    group-data-[sidebar-size=sm]:rounded-b-none 
                    [&.dropdown-button]:before:absolute 
                    [&.dropdown-button]:[&.show]:before:content-['\ea4e'] 
                    [&.dropdown-button]:before:content-['\ea6e'] 
                    [&.dropdown-button]:before:font-remix 
                    [&.dropdown-button]:before:right-2  
                    [&.dropdown-button]:before:text-16 
                    group-data-[sidebar-size=sm]:[&.dropdown-button]:before:hidden 
                    group-data-[sidebar-size=md]:[&.dropdown-button]:before:hidden 
                    "
                  >
                    <span
                      className="min-w-[1.75rem] 
                    group-data-[sidebar-size=sm]:h-[1.75rem] inline-block 
                    text-start text-[16px] group-data-[sidebar-size=md]:block group-data-[sidebar-size=sm]:flex group-data-[sidebar-size=sm]:items-center"
                    >
                      {item.icon &&
                        React.cloneElement(item.icon, {
                          className:
                            "h-4 group-data-[sidebar-size=sm]:h-5 group-data-[sidebar-size=sm]:w-5 transition group-hover/menu-link:animate-icons fill-zink-600 group-hover/menu-link:fill-custom-500/20  group-data-[sidebar-size=md]:block group-data-[sidebar-size=md]:mx-auto group-data-[sidebar-size=md]:mb-2",
                        })}
                    </span>
                    <span
                      className="group-data-[sidebar-size=sm]:pl-10 align-middle group-data-[sidebar-size=sm]:group-hover/sm:block group-data-[sidebar-size=sm]:hidden"
                      data-key="t-dashboards"
                    >
                      {item.label}
                    </span>
                  </Dropdown.Trigger>
                  <Dropdown.Content
                    className="dropdown-content group-data-[sidebar-size=sm]:left-vertical-menu-sm 
                  group-data-[sidebar-size=sm]:w-[calc(theme('spacing.vertical-menu-sm')_*_2.8)] 
                  group-data-[sidebar-size=sm]:absolute group-data-[sidebar-size=sm]:rounded-b-sm bg-zink-700 
                  group-data-[sidebar-size=sm]:hidden group-data-[sidebar-size=sm]:group-hover/sm:block 
                  group-data-[sidebar-size=sm]:rounded-br-md group-data-[sidebar-size=sm]:shadow-lg 
                  group-data-[sidebar-size=sm]:shadow-slate-700/10"
                  >
                    <ul className="pl-[1.75rem] group-data-[sidebar-size=md]:pl-0 group-data-[sidebar-size=sm]:pl-0 group-data-[sidebar-size=sm]:py-2">
                      {item.subItems &&
                        item.subItems
                          ?.filter(
                            (item) =>
                              item?.isStatic ||
                              checkAccess(`${item?.action}_${item?.id}`)
                          )
                          ?.map((subitem, key) => (
                            <React.Fragment key={key}>
                              {!subitem.subItems ? (
                                <li key={key}>
                                  <NavLink
                                    to={subitem.link}
                                    className="relative flex items-center 
                                   px-6 py-2 text-vertical-menu-item-font-size 
                                   transition-all duration-75 ease-linear 
                                   before:absolute 
                                   before:left-1.5
                                   before:top-4 before:w-1 
                                   before:h-1 
                                   before:rounded 
                                   before:transition-all 
                                   before:duration-75 
                                   before:ease-linear 
                                   before:bg-vertical-menu-sub-item-dark/50 
                                   text-zink-200 
                                   hover:text-custom-500 
                                   hover:before:bg-custom-500 
                                   [&.active]:text-custom-500 
                                   [&.active]:before:bg-custom-500 
                                   group-data-[sidebar-size=md]:before:hidden 
                                   group-data-[sidebar-size=md]:text-center 
                                   group-data-[sidebar-size=md]:block 
                                   group-data-[sidebar-size=sm]:before:hidden 
                                   "
                                    data-key="t-analytics"
                                  >
                                    {subitem.label}
                                  </NavLink>
                                </li>
                              ) : (
                                <Dropdown
                                  subitemId={subitem.id}
                                  className="group/sub"
                                >
                                  <Dropdown.Trigger
                                    type="a"
                                    className="sub-drop-down-btn dropdown-button relative flex 
                                    items-center px-6 py-2 text-vertical-menu-item-font-size transition-all duration-75 
                                    ease-linear text-vertical-menu-sub-item hover:text-vertical-menu-sub-item-hover [&.active]:text-vertical-menu-sub-item-active 
                                    before:absolute before:left-1.5 before:top-4 before:w-1 before:h-1 before:rounded before:transition-all 
                                    before:duration-75 before:ease-linear before:bg-vertical-menu-sub-item hover:before:bg-vertical-menu-sub-item-hover [&.active]:before:bg-vertical-menu-sub-item-active group-data-[sidebar=dark]:text-vertical-menu-sub-item-dark group-data-[sidebar=dark]:dark:text-zink-200 group-data-[sidebar=dark]:hover:text-vertical-menu-sub-item-hover-dark group-data-[sidebar=dark]:[&.active]:text-vertical-menu-sub-item-active-dark group-data-[sidebar=dark]:before:bg-vertical-menu-sub-item-dark/50 group-data-[sidebar=dark]:hover:before:bg-vertical-menu-sub-item-hover-dark group-data-[sidebar=dark]:[&.active]:before:text-vertical-menu-sub-item-active-dark group-data-[sidebar=brand]:text-vertical-menu-sub-item-brand group-data-[sidebar=brand]:hover:text-vertical-menu-sub-item-hover-brand group-data-[sidebar=brand]:before:bg-vertical-menu-sub-item-brand/80 group-data-[sidebar=brand]:hover:before:bg-vertical-menu-sub-item-hover-brand/80 group-data-[sidebar=brand]:[&.active]:before:bg-vertical-menu-sub-item-active-brand/80 group-data-[sidebar=brand]:[&.active]:text-vertical-menu-sub-item-active-brand group-data-[sidebar=modern]:text-vertical-menu-sub-item-modern group-data-[sidebar=modern]:before:bg-vertical-menu-sub-item-modern/70 group-data-[sidebar=modern]:hover:text-vertical-menu-sub-item-hover-modern group-data-[sidebar=modern]:before:hover:bg-vertical-menu-sub-item-hover-modern group-data-[sidebar=modern]:[&.active]:text-vertical-menu-sub-item-active-modern group-data-[sidebar=modern]:before:[&.active]:text-vertical-menu-sub-item-active-modern 
                                    group-data-[sidebar-size=md]:before:hidden group-data-[sidebar-size=md]:text-center group-data-[sidebar-size=md]:block 
                                    group-data-[sidebar-size=sm]:before:hidden group-data-[layout=horizontal]:px-5 [&.dropdown-button]:after:absolute 
                                    [&.dropdown-button]:[&.show]:after:content-['\ea4e'] [&.dropdown-button]:after:content-['\ea6e'] 
                                    [&.dropdown-button]:after:font-remix [&.dropdown-button]:after:right-5 [&.dropdown-button]:after:text-16 
                                    group-data-[sidebar-size=sm]:[&.dropdown-button]:after:hidden group-data-[sidebar-size=md]:[&.dropdown-button]:after:hidden 
                                    group-data-[layout=horizontal]:before:left-[1.4rem] group-data-[layout=horizontal]:md:before:hidden 
                                      group-data-[layout=horizontal]:md:!px-5"
                                    href="/#"
                                  >
                                    <span
                                      className="align-middle"
                                      data-key="t-products"
                                    >
                                      {subitem.label}
                                    </span>
                                  </Dropdown.Trigger>
                                  <Dropdown.Content
                                    className="dropdown-content group-data-[sidebar-size=sm]:left-vertical-menu-sm group-data-[sidebar-size=sm]:w-[calc(theme('spacing.vertical-menu-sm')_*_2.8)] group-data-[sidebar-size=sm]:absolute group-data-[sidebar-size=sm]:rounded-b-sm bg-vertical-menu 
                                    bg-vertical-menu-dark 
                                    bg-zink-700 
                                    group-data-[sidebar-size=sm]:group-hover/sm:block 
                                    group-data-[sidebar-size=sm]:rounded-br-md group-data-[sidebar-size=sm]:shadow-lg 
                                    group-data-[sidebar-size=sm]:shadow-slate-700/10 
                                    group-data-[sidebar-size=sm]:group-hover/sm:block 
                                    group-data-[sidebar-size=sm]:rounded-br-md "
                                  >
                                    <ul className="pl-[1.75rem]  group-data-[sidebar-size=md]:pl-0 group-data-[sidebar-size=sm]:pl-0 group-data-[sidebar-size=sm]:py-2">
                                      {(subitem.subItems || [])?.map(
                                        (item, key) => (
                                          <li key={key}>
                                            <NavLink
                                              to={item.link}
                                              className="relative flex items-center px-6 py-2 
                                            text-vertical-menu-item-font-size transition-all duration-75 ease-linear 
                                            before:absolute before:left-1.5 
                                            before:top-4 before:w-1 before:h-1 
                                            before:rounded 
                                            before:transition-all 
                                            before:duration-75 
                                            before:ease-linear 
                                            before:bg-vertical-menu-sub-item-dark/50 
                                            text-zink-200 
                                            hover:text-custom-500 
                                            hover:before:bg-custom-500 
                                            [&.active]:text-custom-500 
                                            [&.active]:before:bg-custom-500 
                                            group-data-[sidebar-size=md]:before:hidden 
                                            group-data-[sidebar-size=md]:text-center 
                                            group-data-[sidebar-size=md]:block 
                                            group-data-[sidebar-size=sm]:before:hidden
                                            "
                                              data-key="t-analytics"
                                            >
                                              {item.label}
                                            </NavLink>
                                          </li>
                                        )
                                      )}
                                    </ul>
                                  </Dropdown.Content>
                                </Dropdown>
                              )}
                            </React.Fragment>
                          ))}
                    </ul>
                  </Dropdown.Content>
                </Dropdown>
              ) : (
                <li className="relative group/sm">
                  <NavLink
                    className="relative flex items-center pl-3 pr-5 mx-3 my-1 group/menu-link text-vertical-menu-item-font-size font-normal 
                    transition-all duration-75 ease-linear rounded-md py-2.5 
                    bg-zink-700
                    text-zink-200 
                    hover:text-custom-500 
                    hover:bg-zink-600 
                    [&.active]:text-vertical-menu-item-active-dark 
                    [&.active]:bg-vertical-menu-item-bg-active-dark 
                    group-data-[sidebar-size=md]:block 
                    group-data-[sidebar-size=md]:text-center 
                    group-data-[sidebar-size=sm]:group-hover/sm:w-[calc(theme('spacing.vertical-menu-sm')_*_3.63)] 
                    group-data-[sidebar-size=sm]:group-hover/sm:bg-vertical-menu-dark 
                    group-data-[sidebar-size=sm]:my-0 
                    group-data-[sidebar-size=sm]:rounded-b-none
                    [&.dropdown-button]:before:absolute 
                    [&.dropdown-button]:[&.show]:before:content-['\ea4e'] 
                    [&.dropdown-button]:before:content-['\ea6e'] 
                    [&.dropdown-button]:before:font-remix [&.dropdown-button]:before:right-2
                    [&.dropdown-button]:before:text-16 group-data-[sidebar-size=sm]:[&.dropdown-button]:before:hidden 
                    group-data-[sidebar-size=md]:[&.dropdown-button]:before:hidden 
                   "
                    to={item.link}
                  >
                    <span className="min-w-[1.75rem] group-data-[sidebar-size=sm]:h-[1.75rem] inline-block text-start text-[16px] group-data-[sidebar-size=md]:block group-data-[sidebar-size=sm]:flex group-data-[sidebar-size=sm]:items-center">
                      {item.icon &&
                        React.cloneElement(item.icon, {
                          className:
                            "h-4 group-data-[sidebar-size=sm]:h-5 group-data-[sidebar-size=sm]:w-5 transition group-hover/menu-link:animate-icons fill-zink-600 group-hover/menu-link:fill-custom-500/20 group-data-[sidebar-size=md]:block group-data-[sidebar-size=md]:mx-auto group-data-[sidebar-size=md]:mb-2",
                        })}
                    </span>
                    <span
                      className="group-data-[sidebar-size=sm]:pl-10 align-middle group-data-[sidebar-size=sm]:group-hover/sm:block group-data-[sidebar-size=sm]:hidden"
                      data-key="t-chat"
                    >
                      {item.label}
                    </span>
                  </NavLink>
                </li>
              )}
            </React.Fragment>
          );
        })}
    </React.Fragment>
  );
};

export default withRouter(VerticalLayout);
